import {SubAPI} from "./SubAPI";
import {SubmissionKind} from "./Submissions";

export class Print extends SubAPI {

    print(input: {
        content: string;
        name: string;
        args?: string;
    }) {
        return this.fetcher.postFormData<{uuid: string}>("/api/printing/print", input);
    }

    getStatus(input: StartPrintResult) {
        return this.fetcher.get<PrintStatus>("/api/printing/status", input);
    }

    download(input: StartPrintResult) {
        const c = new URLSearchParams();
        c.set("uuid", input.uuid);

        window.open("/api/printing/download?" + c.toString(), "_blank");
    }

    async fileAsImage(file: number) {
        const result = await this.fetcher.get("/api/printing/file-to-image", {
            file: file,
        }) as Response;

        const blob = await result.blob();

        return new Promise<string>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                resolve(fr.result as any);
            }

            fr.onerror = () => {
                reject(fr.error);
            }

            fr.readAsDataURL(blob)
        });
    }
}

export type PrintArgs = {
    submissionForProject?: number;
    submissionType?: SubmissionKind;
    ignoreSubmissionDate?: boolean;
    appendCatalogPagesForProducts?: number[];
    appendFiles?: number[];
    attachToChangeset?: number;
    noDownload?: boolean;
}

export type PrintStatus = {
    status: string;
    failed: boolean;
    ready: boolean;
    submission: number;
}

export type StartPrintResult = {
    uuid: string;
}