import { SubAPI } from "./SubAPI";
export class ProductCatalogs extends SubAPI {
    list(props: {
        manufacturer?: number;
    }) {
        return this.fetcher.get<Catalog[]>("/api/product-catalogs", props);
    }

    upsert(input: Catalog) {
        return this.fetcher.post<{}>("/api/product-catalog", input)
    }

    upload(input: {
        file: File;
        manufacturer: number
    }) {
        return this.fetcher.postFormData<Catalog>("/api/product-catalog/upload", {
            file: input.file,
            meta: JSON.stringify({
                manufacturer: input.manufacturer,
            })
        })
    }

    fileLink(id: number, type: "inline" | "attachment" = "attachment") {
        return "/api/product-catalog/file?id=" + id + "&type=" + type
    }

}

export type Catalog = {
    id: number;
    name: string;
    productCount: number;
    manufacturer: number;
    manufacturerName: string;
    pages: string;
    archived: boolean;
}