import {useEffect} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useRetry} from "./UseRetry";

export function useFocusMode(props: {
    enabled: boolean;
    focusRef: HTMLElement|null;
    adjustFocus?: (input: HTMLElement, retryLater: () => void) => Element[];
}) {
    const adjustRef = useSyncedRef(props.adjustFocus);
    const retryPanelSetup = useRetry();
    const retryInMsRef = useSyncedRef(retryPanelSetup.inMs);

    useEffect(() => {
        if(!props.enabled) {
            return;
        }

        if(!props.focusRef) {
            console.log("shadow: missing header row")
            return;
        }

        console.log("shadow: setup")

        const el = props.focusRef;

        if(adjustRef.current) {
            let retry = false;
            adjustRef.current(props.focusRef, () => {
                retry = true;
            });

            if(retry) return retryInMsRef.current(100);
        }

        el.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});

        const makePanel = () => {
            const shadow = document.createElement("div");
            shadow.style.position = "fixed";
            shadow.style.zIndex = "1000";
            shadow.style.background = "hsla(0,0%,0%,0.2)"
            document.body.appendChild(shadow);

            return shadow;
        }

        const left = makePanel();
        const right = makePanel()
        const top = makePanel();
        const bottom = makePanel();
        const obs = new ResizeObserver(input => update())
        let lastWatch: Element[] = [];

        const update = () => {

            let watch: Element[];

            if(adjustRef.current) {
                watch = adjustRef.current(el, () => {});
            } else {
                watch = [el];
            }

            if(watch.length === 0) return;

            lastWatch.map(w => obs.unobserve(w));
            watch.map(w => obs.observe(w));
            lastWatch = watch;

            const r = watch[0].getBoundingClientRect();
            const baseRect = {
                top: r.top,
                bottom: r.bottom,
                left: r.left,
                right: r.right,
                height: 0,
                width: 0,
            }

            watch.map(r => {
                const add = r.getBoundingClientRect();
                baseRect.top = Math.min(baseRect.top, add.top);
                baseRect.bottom = Math.max(baseRect.bottom, add.bottom);
                baseRect.left = Math.min(baseRect.left, add.left);
                baseRect.right = Math.max(baseRect.right, add.right);
            })

            baseRect.height = baseRect.bottom - baseRect.top;
            baseRect.width = baseRect.right - baseRect.left;

            top.style.top = "0";
            top.style.left = "0";
            top.style.right = "0";
            top.style.height = `${baseRect.top}px`;

            bottom.style.bottom = "0";
            bottom.style.left = "0";
            bottom.style.right = "0";
            bottom.style.height = `${window.innerHeight - baseRect.bottom}px`;

            left.style.left = "0";
            left.style.top = `${baseRect.top}px`;
            left.style.width = `${baseRect.left}px`;
            left.style.height = `${baseRect.height}px`;

            right.style.right = "0";
            right.style.top = `${baseRect.top}px`;
            right.style.width = `${window.innerWidth - baseRect.right}px`;
            right.style.height = `${baseRect.height}px`;
        }

        update();

        return () => {
            obs.disconnect();

            top.remove();
            bottom.remove();
            left.remove();
            right.remove();
        }
    }, [props.focusRef, props.enabled, retryPanelSetup.variable, retryInMsRef, adjustRef])
}