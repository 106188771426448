import {hasPermission, useUser} from "../../../../misc/Permission";
import {api} from "../../../../api/API";
import {
    lookupNestedProp,
    Table,
} from "../../../../misc/scroller/Table";
import React, {useContext} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {priceDetailEmitter} from "../../shopdrawing/pricing/UnPricedItems";
import {getAll} from "../../shopdrawing/openings/Openings";
import {commonGroupBy} from "../../shopdrawing/pricing/Frames";
import {TDiv10Type} from "../../shopdrawing/div10/Div10Tabs";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {getColumns} from "../../shopdrawing/pricing/Div10";

export function QuoteDiv10(props: {
    type: TDiv10Type;
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative()
    const customize = usePriceCellCustomize();

    if(!u) return null;

    const nameRender = (row: QuotePriceLine, key: keyof QuotePriceLine) => lookupNestedProp(row, key)
    const colInfo = getColumns(props.type, true, u, nameRender as any, false);

    return (
        <Table<QuotePriceLine>
            key={props.type}
            cellCustomize={customize}
            name={priceTableName(project, "div10", props.type)}
            globalPrefsName={priceTableName(0, "div10", props.type)}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={async (ctx) => {
                const rs = await getAll(ctx, offset => api.quotePricing.list({project: project, alternative, offset}))
                priceDetailEmitter.emit(rs);
                return rs;
            }}
            columns={colInfo.columns}
            columnDeps={[props.type, u]}
            fetchDeps={[project, alternative]}
            groupBy={Object.assign({}, commonGroupBy, {
                groupFilter: (groupName: string) => groupName === "div-10/" + props.type || groupName === "project",
            })}
            onChange={(input) => api.quotePricing.upsert(input)}
        />
    )
}