import {hasPermission, useUser} from "../../../../misc/Permission";
import {api} from "../../../../api/API";
import {
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import React, {useContext} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {priceDetailEmitter} from "../../shopdrawing/pricing/UnPricedItems";
import {getAll} from "../../shopdrawing/openings/Openings";
import {commonGroupBy, commonPricingColumns} from "../../shopdrawing/pricing/Frames";
import {QuotePriceLine} from "../../../../api/QuotePricing";


export function QuoteFrames() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative();
    const customize = usePriceCellCustomize();
    if(!u) return null;

    return (
        <Table<QuotePriceLine>
            name={priceTableName(project, "frame")}
            globalPrefsName={priceTableName(0, "frame")}
            cellCustomize={customize}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={async (ctx) => {
                const rs = await getAll(ctx, offset =>
                        api.quotePricing.list({project: project, alternative, offset})
                )

                rs.sort((a, b) => {
                    if(a.productType === b.productType) return 0;
                    if(a.productType === "frame-anchor") return 1;
                    if(b.productType === "frame-anchor") return -1;
                    return 0;
                })

                priceDetailEmitter.emit(rs);
                return rs;
            }}
            columns={[
                ViewOnly({
                    name: "Series",
                    render: (dt: QuotePriceLine) => {
                        if(dt.productType === "frame-anchor") return `Anchor: ${dt.productCode}`;
                        if(dt.productType === "frame-prep") return `${dt.productName}: ${dt.productCode}`;
                        return dt.frame.series
                    },
                    width: 200,
                }),
                ViewOnly(StringCol("Material", "frame.material")),
                ViewOnly(StringCol("Gauge", "frame.gauge")),
                ViewOnly(StringCol("Screen Elev", "frame.screenElev")),
                ViewOnly(StringCol("Profile", "frame.profile")),
                ViewOnly(StringCol("Jamb Depth", "frame.jambDepth")),
                ViewOnly(StringCol("Construction", "frame.construction")),
                ViewOnly(StringCol("Handing", "frame.handing")),
                ViewOnly(StringCol("Label", "frame.label")),
                ViewOnly(StringCol("Type", "frame.type")),
                ViewOnly(StringCol("Height", "frame.height")),
                ViewOnly(StringCol("Width", "frame.width")),
                ...commonPricingColumns(u, true),
            ]}
            fetchDeps={[project, alternative]}
            columnDeps={[u]}
            groupBy={Object.assign({}, commonGroupBy, {
                groupFilter: (groupName: string) =>
                    groupName === "frame" || groupName === "frame-anchor" ||
                    groupName === "project" || groupName === "frame-totals" || groupName === "frame-prep",
            })}
            onChange={(input) => api.quotePricing.upsert(input)}
        />
    )
}