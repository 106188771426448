import MoreHoriz from "@mui/icons-material/MoreHoriz";
import {ListItemText, MenuItem, Tooltip} from "@mui/material";
import {useCallback, useContext, useState} from "react";
import {useSnackbar} from "../../../../misc/Snackbar";
import { api } from "../../../../api/API";
import {RowRefContext, TableContext} from "../../../../misc/scroller/Table";
import { Popover } from "../../../../misc/Popover";
import {css} from "@emotion/css";
import {red} from "@mui/material/colors";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {DragContext} from "../../../../misc/scroller/DragContext";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {TablePersonalizeContext} from "../../../../misc/scroller/TablePersonalize";

export const wrapperClass = css({
    display: "flex",
    alignItems: "center",
    height: 14,
})

export function RowActions(props: {
    row: QuoteOpening
}) {
    const snack = useSnackbar();
    const tbl = useContext(TableContext);

    return <RowActionsBase draggable>
        {onClose => <>
            <MenuItem onClick={async () => {
                snack.loading();
                try {
                    await api.quoteOpenings.upsert(Object.assign({}, props.row, {
                        id: 0,
                        name: props.row.name ? props.row.name + " (duplicate)" : "",

                        sortOrder: props.row.sortOrder+1,
                    }));

                    snack.success("Duplicated")
                    await tbl.reload(true);
                } catch (e: any) {
                    snack.error(e.toString());
                }

                onClose()
            }}>
                <ListItemText>Duplicate Row</ListItemText>
            </MenuItem>

            <MenuItem onClick={async () => {
                snack.loading();
                try {
                    await api.quoteOpenings.upsert(Object.assign({}, props.row, {
                        archived: true,
                    }));

                    snack.success("Archived")
                    await tbl.reload(true);
                } catch (e: any) {
                    snack.error(e.toString());
                }
                onClose();
            }}>
                <ListItemText style={{color: red["800"]}}>Archive</ListItemText>
            </MenuItem>
        </>}
    </RowActionsBase>
}

export function DragIcon(props: {
    style?: any;
}) {
    const dragCtx = useContext(DragContext);
    const rowRef = useContext(RowRefContext);
    const info = useContext(TablePersonalizeContext);
    const disabled = info.maxFixedIndex > -1;

    if(disabled) {
        return <Tooltip title="Tables with fixed columns cannot be drag/dropped">
            <div className={DisabledBtnClass}>
                <DragIndicatorIcon fontSize="inherit" color="disabled" />
            </div>
        </Tooltip>
    }

    return (
        <div
            style={props.style}
            className={BtnClass}
            onMouseDown={e => dragCtx.onMouseDown(rowRef.index.current, rowRef.element.current, e)}>

            <DragIndicatorIcon fontSize="inherit" />
        </div>
    )
}

export function RowActionsBase(props: {
    children: (onClose: () => void) => any
    draggable?: boolean;
}) {
    const [showMore, setShowMore] = useState<HTMLDivElement|null>(null);
    const close = useCallback(() => setShowMore(null), []);

    return <div className={wrapperClass}>
        {props.draggable && <DragIcon />}
        <div className={BtnClass} onClick={e => setShowMore(e.currentTarget)}>
            <MoreHoriz fontSize="inherit" />
        </div>

        {showMore && <Popover
            open={true}
            anchorEl={showMore}
            onClose={() => setShowMore(null)}
        >
            <div style={{height: 4}} />
            {props.children(close)}
            <div style={{height: 4}} />
        </Popover>}
    </div>
}

export const btnBase = {
    fontSize: "1rem",
    height: "1.2rem",
    width: "1.2rem",
    borderRadius: "50%",

    marginTop: 5,
    marginLeft: 5,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}

export const BtnClass = css({
    ...btnBase,
    cursor: "pointer",

    "&:hover": {
        backgroundColor: "hsla(0,0%,0%,0.1)"
    }
})

const DisabledBtnClass = css({
    ...btnBase,
})