import {useState} from "react";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useAsyncAction} from "nate-react-api-helpers";
import useWindowSize from "react-use/lib/useWindowSize";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField} from "@mui/material";
import {AutoComplete} from "../openings/AutoComplete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from "@mui/icons-material/Clear";
import {FileItemSelection} from "./ProductCatalogLookup";
import {useFilePicker} from "./FilePicker";

export function FileLookup(props: {
    title: string;
    manufacturer?: number;
    uploadFx(input: {
        file: File;
        manufacturer: number
    }): Promise<FileItemSelection>;
    list(input: {
        manufacturer?: number;
    }): Promise<FileItemSelection[]>;
    fileLink(id: number): string
    onDone(value: FileItemSelection | null): Promise<void>;
    value: FileItemSelection | null;
    placeholder: string;
}) {
    const filePicker = useFilePicker({
        accept: ["application/pdf"],
    });
    const [value, setValue] = useState(props.value);
    const uploadFx = useSyncedRef(props.uploadFx);

    const action = useAsyncAction(async (file) => {
        if(!props.manufacturer) throw new Error("Fill out manufacturer first");
        const catalog = await uploadFx.current({
            file: file,
            manufacturer: props.manufacturer,
        });

        setValue(catalog);
        setPages("")
    }, [filePicker, props.manufacturer]);

    const onDoneRef = useSyncedRef(props.onDone);
    const update = useAsyncAction((input) => onDoneRef.current(input), []);
    const [pages, setPages] = useState(props.value?.pages || "");

    const size = useWindowSize();

    return (
        <Dialog open fullWidth maxWidth="lg">
            <form onSubmit={e => {
                e.preventDefault()
                update.callback(Object.assign({}, value, {
                    pages: pages,
                }));
            }}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            {value ? <iframe
                                src={props.fileLink(value.id)}
                                style={{
                                    height: size.height * 0.75,
                                    width: "100%",
                                    border: "1px solid black",
                                }}
                            ></iframe> : <div>Choose a {props.placeholder} on the right</div>}
                        </Grid>
                        <Grid item xs={4}>
                            <AutoComplete<FileItemSelection>
                                label={props.placeholder}
                                size="small"
                                InputProps={{
                                    endAdornment: <>
                                        <IconButton size="small" disabled={!props.manufacturer} onClick={async () => {
                                            if(!props.manufacturer) return;
                                            const files = await filePicker();
                                            action.callback(files[0])
                                        }}>
                                            <FileUploadIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => setValue(null)}>
                                            <ClearIcon />
                                        </IconButton>
                                    </>,
                                }}
                                search={async search => {
                                    const list = await props.list({
                                        manufacturer: props.manufacturer
                                    });

                                    if(!search) {
                                        return list;
                                    }

                                    return list.filter(l => l.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                                }}
                                value={value}
                                equals={(a, b) => a.id === b.id}
                                makeLabel={a => a.name}
                                onSelect={v => {
                                    setValue(v)
                                    setPages("")
                                }}
                                fullWidth
                            />
                            {action.LoadingElement}
                            <div style={{height: 8}} />
                            <TextField label="Pages"
                                       placeholder="1-5"
                                       helperText={"Use range (e.g. 1-5) or blank to include entire document"}
                                       value={pages}
                                       onChange={e => setPages(e.target.value)}
                                       size="small"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {update.LoadingElement}
                    <Button onClick={e => props.onDone(value as any)}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
