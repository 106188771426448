import {AdjustCol, CentsCol, NumberCol, StringCol, Table, ViewOnly} from "../../../../misc/scroller/Table";
import {priceTableName} from "./Pricing";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {priceDetailEmitter} from "./UnPricedItems";
import React, {useContext} from "react";
import {useProjectId} from "../ProjectName";
import {useAlternative} from "../../quote/alternative/Alternative";
import {useIsQuote} from "../../quote/QuoteContainer";
import {commonGroupBy, isPriceLine2} from "./Frames";
import {getSectionName} from "./BeforeGroup";
import {sum} from "nate-react-api-helpers";
import {ChangesetReviewContext} from "../changeset/ChangesetContainer";
import {useMiscPrices} from "../../quote/pricing/UseMiscPricing";
import {useProposingKit} from "./ProposingKit";
import {PriceLine} from "../../../../api/Pricing";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";

type PriceSummaryLine = {
    id: number;
    name: string;
    extendedCost: number;
    qty: number;
    markup: string;
    unitPrice: number;
    extendedPrice: number;
    profitActual: number;
    profitProjected: number;
    marginProjected: string;
}

export function Summary() {
    const project = useProjectId();
    const alternative = useAlternative();
    const isQuote = useIsQuote();
    const isTrackingReview = useContext(ChangesetReviewContext);
    const u = useUser();

    const proposal = useProposingKit();

    const miscPrices = useMiscPrices({
        project,
        alternative,
    })

    const previewChangeset = useContext(ChangePreviewContext);

    if(u === null) return null;

    return (
        <Table<PriceSummaryLine>
            name={priceTableName(project, "summary")}
            globalPrefsName={priceTableName(0, "summary")}
            fetch={async (ctx) => {
                let rs = await getAll(ctx, offset =>
                    isQuote ? api.quotePricing.list({project: project, alternative, offset}) :
                        api.pricing.list({
                            project: project,
                            offset,
                            previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined,
                        })
                )

                if(!isQuote) {
                    rs = proposal.transformRows(rs as any)
                    rs = rs.filter(v => !isPriceLine2(v) || v.kind === "current")
                }

                priceDetailEmitter.emit(rs);

                let groups: PriceSummaryLine[] = [];
                let groupObj = {} as {[k: string]: PriceSummaryLine};

                rs.map(pr => {
                    if(isTrackingReview && !pr.projectChangesetActive) {
                        return false;
                    }

                    const name = getSectionName(commonGroupBy.groupFx(pr))
                    let value = groupObj[name]
                    if(!value) {
                        value = {
                            id: groups.length+1,
                            name: name,
                            qty: 0,
                            extendedCost: 0,
                            markup: "-",
                            unitPrice: 0,
                            extendedPrice: 0,
                            profitActual: 0,
                            profitProjected: 0,
                            marginProjected: "-",
                        };

                        groupObj[name] = value;
                        groups.push(value);
                    }

                    if(proposal.proposing) {
                        if (isPriceLine2(pr) && pr.kind === "current") {
                            // @ts-ignore
                            const prev = pr.previous as PriceLine;
                            value.extendedCost += pr.unitCostCents * pr.qty
                            value.extendedPrice += pr.extendedPrice + prev.extendedPrice;
                            value.qty += pr.qty + prev.qty;
                            console.log("here", pr)
                        }
                    } else {
                        value.qty += pr.qty;
                        value.extendedCost += pr.unitCostCents * pr.qty
                        value.unitPrice += (pr.extendedPrice / pr.qty);
                        value.extendedPrice += pr.extendedPrice;
                        value.profitActual += (pr.revenueDelivered || 0) - (pr.costDelivered || 0);
                    }
                })

                const misc = (await miscPrices.get()).filter(v => v.extendedPrice > 0 || !!v.publicNote)
                const miscTotal = sum(misc.map(m => m.extendedPrice - (m.backup?.extendedPrice || 0)));
                const MiscGroupName = "Misc";
                groups.push({
                    id: groups.length+1,
                    name: MiscGroupName,
                    qty: misc.length,
                    unitPrice: 0,
                    extendedCost: miscTotal,
                    extendedPrice: miscTotal,
                    markup: "-",
                    profitActual: 0,
                    profitProjected: 0,
                    marginProjected: "-",
                })

                groups.push({
                    id: groups.length+1,
                    name: "Total",
                    qty: sum(groups.map(g => g.qty)),
                    extendedCost: sum(groups.map(g => g.extendedCost)),
                    markup: "-",
                    unitPrice: sum(groups.map(g => g.unitPrice)),
                    extendedPrice: sum(groups.map(g => g.extendedPrice)),
                    profitActual: sum(groups.map(g => g.profitActual)),
                    profitProjected: sum(groups.map(g => g.profitProjected)),
                    marginProjected: "-",
                })

                groups.map(gr => {
                    if(gr.name === MiscGroupName) return

                    if(proposal.proposing) return;

                    gr.profitProjected = (gr.extendedPrice - gr.extendedCost)
                    if(gr.extendedCost !== 0) {
                        gr.markup = Math.round((gr.extendedPrice / gr.extendedCost - 1) * 100) + "%"
                    }

                    if(gr.extendedPrice !== 0) {
                        let margin = Math.round(gr.profitProjected / gr.extendedPrice * 100);
                        gr.marginProjected = margin + "%"
                    }
                })

                return groups;
            }}
            columns={[
                ViewOnly(StringCol("Name", "name", 150)),
                ViewOnly(NumberCol("Qty", "qty", 150)),
                ViewOnly(CentsCol("Total Cost", "extendedCost", 80)),
                ViewOnly(AdjustCol(StringCol("Markup", "markup", 80), {
                    alignRight: true,
                })),
                ViewOnly(CentsCol("Total Price", "extendedPrice", 100)),
                hasPermission(u, "CanViewProjectPricingProfit") && ViewOnly(CentsCol("Proj. Profit", "profitProjected", 100)),
                hasPermission(u, "CanViewProjectPricingProfit") && ViewOnly(CentsCol("Act. Profit", "profitActual", 80)),
                ViewOnly(AdjustCol(StringCol("Proj. Margin", "marginProjected", 80), {
                    alignRight: true,
                })),
            ]}
            fetchDeps={[project, alternative, proposal.proposing, previewChangeset.enabled, previewChangeset.sessionId]}
        />
    )
}