import {Menu, MenuItem, styled, Tab, Tabs} from "@mui/material";
import {
    Column,
    lookupNestedProp,
    NestedKeyOf,
    StringCol,
    TableSelectionContext,
    TID
} from "../../../misc/scroller/Table";
import React, {useContext, useEffect, useState} from "react";
import {grey} from "@mui/material/colors";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {css} from "@emotion/css";
import {FilterContext} from "../../../misc/scroller/Filter";
import {CheckboxProvider} from "../../project/shopdrawing/release/Checkbox";
import { TabWrapper } from "../../project/shopdrawing/release/Release";
import {Hardware} from "./toSource/Hardware";
import {Door} from "./toSource/Door";
import {Frame} from "./toSource/Frame";
import {SubmitButton} from "./toSource/SubmitButton";
import {purchasingPopoutPanel} from "./PurchaseOrderList";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {Div10Tabs, TDiv10Type} from "../../project/shopdrawing/div10/Div10Tabs";
import {Div10} from "./toSource/Div10";
import {FrameAnchors} from "./toSource/FrameAnchors";
import {InventoryModeToggle} from "./InventoryModeToggle";
import {Component} from "./toSource/Component";

export const toSourceTableName = "purchasing.to-source-items"

export function ToSourcePopout() {

    const ctx = useContext(TableSelectionContext);
    const refreshTable = useSyncedRef(ctx.refresh);
    const [props, setProps] = useState<ToSourceProps|null>(null);

    useEffect(() => {
        purchasingPopoutPanel.sendToServer("ping")
        const sub = purchasingPopoutPanel.fromServer.subscribeAndFireLast(value => {
            if(value === "refresh-to-source") {
                refreshTable.current(toSourceTableName);
            } else {
                setProps(value);
            }
        })

        return () => sub.cancel();
    }, [refreshTable]);

    if(!props) {
        return (<PartsToSource isDraft={false} />)
    }

    return (<PartsToSource {...props} />);
}

export type ToSourceProps = {
    purchaseOrder?: number;
    isDraft: boolean;
    supplier?: {
        id: number;
        name: string;
    }
    defaultTab?: SourceTab;
}

// must match tabs below
export type SourceTab = "hardware"|"frame"|"door"|"div10"

const defaultDiv10Tab = "wr-accessory";

export function PartsToSource(props: ToSourceProps) {
    const [tab, setTab] = useState(props.defaultTab || "hardware")
    const [tab2, setTab2] = useState<TDiv10Type>(defaultDiv10Tab)
    const [sourceMode, setSourceMode] = useState<"to-order"|"stock">("to-order")

    useEffect(() => {
        setTab(props.defaultTab || "hardware")
    }, [props.defaultTab]);

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <SourceHeader>
                Parts to Source
            </SourceHeader>

            <CheckboxProvider>
                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                }}>
                    <TabWrapper>
                        <Tabs className={tabGroup} value={tab} onChange={(e, value) => {
                            setTab(value)
                            setTab2(defaultDiv10Tab)
                        }}>
                            <Tab label="Frames" value="frame" />
                            <Tab label="Doors" value="door" />
                            <Tab label="Hardware" value="hardware" />
                            <Tab label="Div 10" value="div10" />
                            <Tab label="Anchors" value="frame-anchors" />
                            <Tab label="Components" value="component" />
                            <div style={{flex: 1}} />
                        </Tabs>
                        <div className={rightGroup}>
                            <InventoryModeToggle value={sourceMode} onChange={setSourceMode} />
                            <SubmitButton purchaseOrder={props.purchaseOrder} isDraft={props.isDraft} sourceMode={sourceMode} />
                        </div>
                    </TabWrapper>
                    {tab === "div10" && tab2 && <Div10Tabs tab={tab2} onChange={setTab2} />}


                    <div style={{flex: 1, display: "flex", overflow: "hidden"}}>
                        {tab === "frame" ? <Frame {...props} sourceMode={sourceMode} /> :
                            tab === "door" ? <Door {...props} sourceMode={sourceMode} /> :
                                tab === "hardware" ? <Hardware {...props} sourceMode={sourceMode} /> :
                                    tab === "div10" ? <Div10 {...props} tab={tab2} sourceMode={sourceMode} /> :
                                    tab === "component" ? <Component {...props} sourceMode={sourceMode} /> :
                                        <FrameAnchors {...props} sourceMode={sourceMode} />
                        }
                    </div>
                </div>
            </CheckboxProvider>
        </div>

    )
}

export const tabGroup = css({
    minHeight: "0px !important",
    flex: 1,

    "& .MuiTabs-flexContainer": {
        height: "100%",
    },

    "& .MuiButtonBase-root": {
        paddingTop: 4,
        paddingBottom: 4,
        minHeight: 0,
        fontSize: "0.7rem",
    }
})

const rightGroup = css({
    display: "flex",

    "& .MuiButtonBase-root": {
        paddingTop: 4,
        paddingBottom: 4,
        minHeight: 0,
        fontSize: "0.7rem",
    }
})

export function ProjectCol<T extends TID>(name: string, nameCol: NestedKeyOf<T>, width?: number) {
    let str = StringCol<T>(name, nameCol, width) as Column<T>;

    str.renderFilter = (props) => <ProjectSelector<T> nameCol={nameCol as any} {...props} />
    str.filter = (row: T, value: string) => {
        const val = lookupNestedProp(row, nameCol)
        return val && val.toString().toLowerCase() === value
    }

    return str;
}

function ProjectSelector<T extends object>(props: {
    nameCol: NestedKeyOf<T>;
    value: string;
    onChange(value: string): void;
}) {
    const [open, setOpen] = useState<HTMLElement|null>(null);
    const {srcData} = useContext(FilterContext);
    const [list, setList] = useState<string[]>([]);
    const listRef = useSyncedRef(list);

    useEffect(() => {

        const sub = srcData.subscribeAndFireLast(v => {
            let list: string[] = [];

            (v as T[]).map(rw => {
                const value = lookupNestedProp(rw, props.nameCol) as string;
                if(list.indexOf(value) === -1) {
                    list.push(value);
                }

                return null;
            });

            list.sort();

            if(JSON.stringify(list) !== JSON.stringify(listRef.current)) {
                setList(list);
            }
        })

        return () => sub.cancel();
    }, [listRef, props.nameCol, srcData]);

    return (
        <>
            <button className={selectStyle} onClick={e => setOpen(e.currentTarget)}>
                <div style={{flex: 1}}>{props.value || <span style={{color: grey["600"], fontWeight: "lighter"}}>Filter</span>}</div>
                <ArrowDropDown />
            </button>
            {open && <Menu
                open
                onClose={() => setOpen(null)}
                anchorEl={open}
            >
                <MenuItem onClick={() => {
                    props.onChange("");
                    setOpen(null);
                }}><i>None</i></MenuItem>
                {list.map(l => <MenuItem key={l} onClick={() => {
                    props.onChange(l);
                    setOpen(null);
                }}>{l}</MenuItem>)}
            </Menu>}
        </>
    );
}

const selectStyle = css({

    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
    width: "100%",
    cursor: "pointer",

    display: "flex",
    alignItems: "center",
    background: "none",
    border: "none",
    "&:hover": {
        opacity: 0.7,
    }
})

export const SourceHeader = styled("div")(props => ({
    backgroundColor: props.theme.palette.primary.dark,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    color: "white",
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: "0.8rem",
}))