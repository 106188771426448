import {Container} from "../../misc/Container";
import {api} from "../../api/API";
import {Grid} from "@mui/material";
import React, {useState} from "react";
import { AddProjectModal } from "./AddProjectModal";
import {Project} from "../../api/Projects";
import {useNavigate} from "react-router-dom";
import {sortString, StringCol, Table, ViewOnly} from "../../misc/scroller/Table";
import {getAll} from "./shopdrawing/openings/Openings";
import {SectionHeader} from "../../misc/SectionHeader";
import {SmallSearchBox} from "../logistics/purchasing/SmallSearchBox";
import {WhiteColumn} from "../logistics/purchasing/WhiteColumn";
import {removeRowOption, RowOptionCol} from "../logistics/purchasing/RowOptions";

const projectTableName = "project"

export function ProjectList() {
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState<Project|null>(null);
    const nav = useNavigate()
    const [search, setSearch] = useState("")

    return (<Container>
        <WhiteColumn>
            <SectionHeader name="Projects" right={
                <Grid container spacing={1} flexWrap="nowrap">
                    <Grid item>
                        {showAdd && <AddProjectModal onClose={() => setShowAdd(false)} />}
                        {showEdit && <AddProjectModal edit={showEdit} onClose={() => setShowEdit(null)} />}
                    </Grid>
                    <Grid item>
                        <SmallSearchBox value={search} onChange={setSearch} fullWidth />
                    </Grid>
                </Grid>
            } />
            <Table<Project>
                name={projectTableName}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            removeRowOption(dt, {
                                refreshTables: [projectTableName],
                                confirm: true,
                            })
                        ],
                    }),
                    StringCol("Name", "name", 200),
                    StringCol("Alias", "nameAlias", 200),
                    ViewOnly(StringCol("Address", "addressStreet", 200)),
                    {
                        name: "Status",
                        render: (dt, col) => {
                            switch (dt.stage) {
                                default:
                                case "quote":
                                    return "Quoting"
                                case "in-progress":
                                    return "Active"
                            }
                        },
                        sort: sortString("stage"),
                        width: 70,
                    },
                    ViewOnly(StringCol("Customer", "clientName", 200)),
                    ViewOnly(StringCol("Contractor", "contractorName", 100)),
                ]}
                fetch={ctx => getAll(ctx, offset => api.projects.list({
                    offset,
                    search,
                }))}
                onChange={input => api.projects.upsert(input)}
                fetchDeps={[search]}
                onRowClick={(item) => {
                    nav("/project/" + item.id)
                }}
                insert={{
                    modal: (onDone) => <AddProjectModal onClose={onDone} />,
                }}
            />
        </WhiteColumn>
    </Container>);
}