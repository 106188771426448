import {useProjectId, useProjectUnitSystem} from "../ProjectName";
import React, {useContext, useEffect, useRef, useState} from "react";
import {api} from "../../../../api/API";
import {useIsQuote} from "../../quote/QuoteContainer";
import {Div10Tabs, Div10TypeToName} from "./Div10Tabs";
import {
    AdjustCol,
    CheckboxCol, Column, DistanceCol, lookupNestedProp, ManufacturerCol,
    NumberCol,
    Option,
    SelectCol,
    StringCol,
    Table, ViewOnly
} from "../../../../misc/scroller/Table";
import {AutoInsert} from "../openings/AutoInsertOpening";
import {getAll, StatusCol} from "../openings/Openings";
import {Div10Row} from "../../../../api/QuoteDiv10";
import {ManagerLockContext} from "../ProjectActions";
import {RowActions} from "./RowActions";
import {orderByStrAscending} from "nate-react-api-helpers";
import {Category, Product, ProductSearchResult} from "../../../../api/Products";
import {UnitSystem} from "../../../../api/Projects";
import {CheckboxSelectorCol} from "../release/Checkbox";
import {ShortCode} from "../../../../api/Library";
import {isShortCode} from "../hardware/PendingHardware";
import {ProductLookup, ProductLookupKind} from "../extras/ProductLookup";
import {Paper, Popper} from "@mui/material";
import {useAlternative} from "../../quote/alternative/Alternative";
import {cellInActive} from "../pricing/cellconst";
import {PreviousValuePopup} from "../openings/PreviousValuePopup";
import {WithChangesetMeta} from "../changeset/ChangesetContext";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {ReleasedAdjustmentContext} from "../../quote/openings/OnsiteAdjustmentsProvider";
import {projectTablePrefName} from "../TablePrefName";

export function useDiv10Api() {
    return useIsQuote() ? api.quoteDiv10 : api.div10
}

export function Div10Table(props: {
    tab: string;
    onTabChange(value: any): void;
}) {
    const project = useProjectId();
    const dapi = useDiv10Api();
    const tab = props.tab;
    const setTab = props.onTabChange;
    const sys = useProjectUnitSystem();
    const alternative = useAlternative();
    const isQuote = useIsQuote();

    const {locked} = useContext(ManagerLockContext)
    const previewChangeset = useContext(ChangePreviewContext);
    const onsiteContext = useContext(ReleasedAdjustmentContext);

    if(!sys) return null;

    const tableName = projectTablePrefName("div10." + tab, project);
    return (
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
        }}>
            <Div10Tabs tab={tab} onChange={setTab} />
            <WithChangesetMeta>
                {changeset => (<Table<Div10Row>
                    key={tab}
                    locked={locked}
                    name={tableName}
                    globalPrefsName={projectTablePrefName("div10." + tab, 0)}
                    onDrag={async (input) => {
                        if(previewChangeset.enabled) return {sortByCol: null};

                        await dapi.reOrder({
                            project: project,
                            quoteAlternative: alternative,
                            idOrder: input.idOrder,
                            tab: tab,
                        })

                        return {
                            sortByCol: null
                        }
                    }}
                    columns={getColumns(tab, project, sys, false, tableName, isQuote)}
                    fetch={ctx => getAll(ctx, offset => dapi.list({
                        project: project,
                        tab: tab,
                        alternative,
                        offset,
                        previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined
                    }))}
                    cellCustomize={{
                        backgroundColor: (row: Div10Row, c: Column<Div10Row>) => {
                            if(!changeset.proposing && !previewChangeset.enabled) return null;

                            let changed = false;

                            if(c.editKey) {
                                if(c.editKey.startsWith("div10.")) {
                                    changed = (row.backup?.archived || (!!row.backupDiv10 && lookupNestedProp(row, c.editKey.replace("div10", "backupDiv10") as any) !== lookupNestedProp(row, c.editKey)))
                                } else {
                                    changed = row.backup?.archived || !!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)
                                }
                            } else if("isChangedSinceBackup" in c && c.isChangedSinceBackup) {
                                changed = c.isChangedSinceBackup(row)
                            } else {
                                changed = false;
                            }

                            if(changed) {
                                return "white"
                            }

                            return cellInActive;
                        },
                        onFocus: (row: Div10Row, c: Column<Div10Row>, anchor: any) => {
                            if(!changeset.proposing && !previewChangeset.enabled) return null;
                            if(!c.editKey) return null

                            if(c.editKey.startsWith("div10.")) {
                                if ((!!row.backup && row.product !== row.backup.project) || (!!row.backupDiv10 && lookupNestedProp(row, c.editKey.replace("div10", "backupDiv10") as any) !== lookupNestedProp(row, c.editKey))) {
                                    return (
                                        <PreviousValuePopup anchor={anchor} width={c.width} value={lookupNestedProp(row, c.editKey.replace("div10", "backupDiv10") as any)}/>
                                    )
                                }
                            } else {
                                if (!!row.backup && lookupNestedProp(row.backup, c.editKey) !== lookupNestedProp(row, c.editKey)) {
                                    return (
                                        <PreviousValuePopup anchor={anchor} width={c.width} value={c.render(row.backup, c)}/>
                                    )
                                }
                            }

                            return null;
                        }
                    }}
                    fetchDeps={[project, previewChangeset.enabled, previewChangeset.sessionId]}
                    insert={previewChangeset.enabled ? undefined : {
                        buttonText: "New Row",
                        alignX: "left",
                        modal: input => <AutoInsert onDone={input} getInput={() => {
                            return ({
                                project: project,
                                quoteAlternative: alternative,
                                category: tab,
                                div10: {
                                    category: tab,
                                }
                            })
                        }} />
                    }}
                    onChange={async (input) => {
                        if(previewChangeset.enabled) return Promise.resolve();

                        try {
                            return await dapi.upsert(input)
                        } catch (e: any) {
                            if(isQuote) {
                                throw e;
                            }

                            return await onsiteContext.onError(input, e, async (input) => {
                                return await dapi.upsert(input)
                            })
                        }

                    }}
                />)}
            </WithChangesetMeta>
        </div>
    )
}

export function getColumns(tab: string, project: number, unitSystem: UnitSystem, release: boolean, tableName: string, isQuote: boolean = false) {
    let firstCol = release ? CheckboxSelectorCol() : RowActionCol();

    switch(tab) {
        case "wr-accessory":
            return [
                firstCol,
                StringCol("Room", "room"),
                WrAccessoryCol(project),
                ViewOnly(StringCol("Category", "productCategoryName")),
                ViewOnly(StringCol("Description", "productName")),
                ViewOnly(StringCol("Manufacturer", "manufacturerName")),
                ViewOnly(StringCol("Finish", "finish")),
                NumberCol("Qty", "qty"),
                !isQuote && StatusCol("Status", "status"),
                StringCol("Note", "note"),
            ]
        case "wr-partition":
            return [
                firstCol,
                StringCol("Room", "room"),
                NumberCol("Qty", "qty"),
                !isQuote && StatusCol("Status", "status"),
                SelectCol("Mounting", "div10.partitionBracing",
                    (input: any) => [
                        "Floor Mount Overhead Braced",
                        "Ceiling Hung",
                        "Floor To Ceiling",
                        "Floor Mount Only",
                    ].map(v => ({display: v, value: v}))),
                SelectCol("Material", "div10.partitionCoating",
                    (input: any) => [
                        "Powder Coated Steel",
                        "Solid Plastic",
                        "Black Core Phenolic",
                        "Colour Thru Phenolic",
                        "Stainless Steel",
                        "Plastic Laminate",
                    ].map(v => ({display: v, value: v}))),
                StringCol("Description", "div10.description"),
                ManufacturerCol("Manufacturer", "manufacturer", "manufacturerName"),
                StringCol("Notes", "note"),
            ]
        case "locker":
            return [
                firstCol,
                StringCol("Room", "room"),
                NumberCol("Qty", "qty"),
                !isQuote && StatusCol("Status", "status"),
                SelectCol("Tiers", "div10.lockerTiers",
                    (input: any) => [
                        "Single Tier",
                        "Double Tier",
                        "Triple Tier",
                        "Four Tier",
                        "Five Tier",
                        "Six Tier",
                        "Two Person Z-Locker",
                    ].map(v => ({display: v, value: v}))),
                SelectCol("Material", "div10.lockerCoating",
                    (input: any) => [
                        "Powder Coated Steel",
                        "Solid Plastic",
                        "Black Core Phenolic",
                        "Colour Thru Phenolic",
                    ].map(v => ({display: v, value: v}))),
                CheckboxCol("Sloped Tops", "div10.lockerSloped"),
                CheckboxCol("End Panels", "div10.lockerEndPanels"),
                CheckboxCol("Base Trim", "div10.lockerBaseTrim"),
                DistanceCol("Width",unitSystem, "dimWidth"),
                DistanceCol("Depth",unitSystem, "dimLength"),
                DistanceCol("Height", unitSystem,"dimHeight"),
                ManufacturerCol("Manufacturer", "manufacturer", "manufacturerName"),
                StringCol("Notes", "note"),
            ]
        case "mailbox":
            return [
                firstCol,
                StringCol("Room", "room"),
                NumberCol("Qty", "qty"),
                !isQuote && StatusCol("Status", "status"),
                NumberCol("Tenant Units", "div10.mailboxTenantUnits"),
                NumberCol("Parcel Units", "div10.mailboxParcelUnits"),
                SelectCol("Loading", "div10.mailboxLoading",
                    (input: any) => [
                        "Front Load",
                        "Rear Load",
                    ].map(v => ({display: v, value: v}))),
                SelectCol("Mounting", "div10.mailboxMounting",
                    (input: any) => [
                        "Wall Mount",
                        "Recessed",
                        "Pedestal",
                    ].map(v => ({display: v, value: v}))),
                ManufacturerCol("Manufacturer", "manufacturer", "manufacturerName"),
                StringCol("Notes", "note"),
            ]
        case "corner-guard":
            return [
                firstCol,
                StringCol("Room", "room"),
                NumberCol("Qty", "qty"),
                !isQuote && StatusCol("Status", "status"),
                SelectCol("Type", "div10.cornerGuardType",
                    (input: any) => [
                        "Stainless Steel",
                        "Vinyl",
                        "Rubber",
                    ].map(v => ({display: v, value: v}))),
                ManufacturerCol("Manufacturer", "manufacturer", "manufacturerName"),
                StringCol("Code", "productCode"),
                StringCol("Finish", "div10.cornerGuardFinish"),
                DistanceCol("Width", unitSystem, "dimWidth"),
                DistanceCol("Height", unitSystem, "dimHeight"),
                StringCol("Notes", "note"),
            ]
        default:
            return [];
    }
}

function WrAccessoryCol(project: number) {
    return {
        name: "Product",
        width: 300,
        editable: {
          type: "custom",
            paste: (row: Div10Row, value: string) => {
                if(value) {
                    row.product = parseInt(value);
                } else {
                    // @ts-ignore
                    row.product = undefined;
                }
            },
            copy: (row: Div10Row) =>{
                return row.product.toString() || ""
            },
            render: Div10ProductCol,
        },
        isChangedSinceBackup: (input: Div10Row) => {
            if(!input.backup) return false;
            return input.product !== input.backup.product
        },
        render: (row: Div10Row) => {
            if(row.product) {
                return row.productCode
            }

            return "";
        },
    }
}

export function Div10ProductCol(props: {
    anchor: any;
    row: Div10Row
    width: number;
    initialValue: string;
    onCancel(): void;
    onDone(value: Div10Row): Promise<any>
}) {

    const dapi = useDiv10Api();
    const alternative = useAlternative();
    const project = useProjectId();

    return <ProductCol
        kind="div-10"
        anchor={props.anchor}
        width={props.width}
        initialValue={props.initialValue}
        shortCodesForProject={project}
        onCancel={() => {
            // mark as done even when it's just a blur because something may have changed while the popup was open
            // (e.g. editing an object)
            props.onDone(Object.assign({}, props.row))
        }}
        onDone={async (value: {product: number, count?: number}[]) => {
            if(value.length === 1) {
                return props.onDone(Object.assign({}, props.row, {
                    product: value[0].product,
                    qty: !!value[0].count ? value[0].count : props.row.qty,
                }))
            } else {
                const list = value.slice(1);

                await Promise.all(list.map(l => dapi.upsert({
                    room: props.row.room,
                    qty: !!l.count ? l.count : props.row.qty,

                    project: project,
                    // @ts-ignore
                    quoteAlternative: alternative as any,
                    category: "wr-accessory",
                    product: l.product,
                    div10: {
                        category: "wr-accessory",
                    } as any
                })))

                // do the update last so that the table will refresh and grab the other items
                const first = value[0]
                await props.onDone(Object.assign({}, props.row, {
                    product: first.product,
                    qty: !!first.count ? first.count : props.row.qty,
                }))
            }
        }}
    />
}

export function ProductCol(props: {
    anchor: any;
    width: number;
    initialValue: string;
    kind: ProductLookupKind;
    onCancel(): void;
} & ({
    shortCodesForProject?: number;
    onDone(products: {
        product: number;
        count?: number;
    }[]): Promise<any>
})) {

    const [ready, setReady] = useState(false);
    useEffect(() => {
        const tm = setTimeout(() => setReady(true), 200);
        return () => clearTimeout(tm);
    }, [])

    const calledDone = useRef(false)
    const popperRef = useRef<{update(): void}|null>(null);

    if(!ready) return null;

    return (
        <Popper open
                popperRef={r => { popperRef.current = r; }}
                 anchorEl={props.anchor}>
            <Paper style={{maxHeight: 400, overflow: "auto", display: "flex"}}>
                <div
                    style={{minWidth: props.width, flex: 1, overflow: "auto", display: "flex", flexDirection: "column"}}
                    onClick={e => e.stopPropagation()}>

                    <ProductLookup
                        onBlur={() => {
                            if(calledDone.current) return;

                            props.onCancel()
                            calledDone.current = true;
                        }}
                        autoFocus
                        initialValue={props.kind === "frame-anchor" ? afterColonPart(props.initialValue) : props.initialValue}
                        placeholder="Enter Shortcode or start typing product name"
                        kind={props.kind}
                        resultsInline
                        isTableCell
                        onRender={() => {
                            popperRef.current?.update();
                        }}
                        onSelect={async (result: ProductSearchResult | ShortCode | Product) => {
                            if(calledDone.current) {
                                console.warn("ProductCol: already called done, but onSelect was called?")
                            }

                            if(isShortCode(result)) {
                                if(!result.items || !result.items.length) return;
                                if("shortCodesForProject" in props) {
                                    await props.onDone(result.items.map(i => ({product: i.product, count: i.qty})))
                                    return;
                                }

                                const first = result.items[0]
                                await props.onDone([{product: first.product}])
                            } else {
                                await props.onDone([{product: result.id}])
                            }

                            calledDone.current = true;
                        }}
                        includeShortCodesForProject={"shortCodesForProject" in props ? props.shortCodesForProject : undefined}
                    />
                </div>
            </Paper>
        </Popper>
    )
}

function afterColonPart(value: string) {
    if(!value) return value;

    const parts = value.split(":")
    if(parts.length >= 2) {
        return parts[1].trim();
    }

    return value;
}

function RowActionCol() {
    return {
        name: "",
        render: (o: any) => <RowActions row={o} />,
        width: 80,
    }
}


export function ProductCategoryCol(tab: string) {
    return AdjustCol(StringCol<Div10Row>("Product", "productCategoryName"), {
        editKey: "productCategory",
        editable: {
            type: "lookup" as "lookup",
            displayKey: "productCategoryName" as "productCategoryName",
            options: async (search: string, data: Div10Row) => {

                const list = await api.products.categories({
                    kind: "div-10",
                    parent: Div10TypeToName(tab),
                })

                const ids = list.data.map(l => l.id);

                const byParent = list.data.reduce((elem, cat) => {
                    if(cat.parentCategory && ids.indexOf(cat.parentCategory) !== -1) {
                        const arr = elem[cat.parentCategory] || [];
                        arr.push(cat);
                        elem[cat.parentCategory] = arr;
                    }
                    return elem;
                }, {} as {[id :number]: Category[]});

                const tld = list.data.filter(v => !v.parentCategory || !byParent.hasOwnProperty(v.parentCategory));
                orderByStrAscending(tld, t => t.name);
                let labels: Option[] = [];

                tld.map(t => {
                    labels.push({
                        display: t.name,
                        value: t.id,
                        disabled: true,
                        subHeading: true,
                    })

                    let group = byParent[t.id].map(v => ({
                        display: v.name,
                        value: v.id,
                    }));

                    if(search) {
                        group = group.filter(l => l.display.toLowerCase().indexOf(search.toLowerCase()) === 0)
                    }

                    labels.push(...group)
                })

                return labels
            }
        }
    } as any);
}