import {hasPermission, useUser} from "../../../../misc/Permission";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {
    AdjustCol,
    BoolCol,
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {priceTableName} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {commonGroupBy, commonPricingColumns, PriceLine2, proposingGroupBy} from "./Frames";
import {TDiv10Type} from "../div10/Div10Tabs";
import {User} from "../../../../api/Users";
import {priceDetailEmitter} from "./UnPricedItems";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";

export function Div10(props: {
    type: TDiv10Type;
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const proposal = useProposingKit();
    const changesetPreview = useContext(ChangePreviewContext);

    if(!u) return null;

    const colInfo = getColumns(props.type, false, u, proposal.renderItemName as any, proposal.proposing);

    return (
        <Table<PriceLine2>
            key={props.type}
            cellCustomize={proposal.cellCustomize}
            name={priceTableName(project, "div10", props.type)}
            globalPrefsName={priceTableName(0, "div10", props.type)}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={async (ctx) => {
                const rs = await getAll(ctx, offset =>
                        api.pricing.list({
                            project: project,
                            offset,
                            previewChangeset: changesetPreview.enabled ? changesetPreview.sessionId : undefined,
                        }))

                const rows = proposal.transformRows(rs)

                priceDetailEmitter.emit(rows);
                return rows;
            }}
            columns={[
                {
                    name: "",
                    render: o => proposal.rowAction(o, "div10", props.type),
                    width: 150,
                },
                ...proposal.modifyColumns(colInfo.columns)
            ]}
            columnDeps={[props.type, u, proposal.proposing]}
            fetchDeps={[project, proposal.proposing, proposal.expanded, changesetPreview.enabled, changesetPreview.sessionId]}
            groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy : commonGroupBy, {
                groupFilter: (groupName: string) => groupName === "div-10/" + props.type || groupName.startsWith("div-10/" + props.type + "/") || groupName === "project",
            })}
            onChange={(input) => api.pricing.upsert(proposal.processUpdate(input))}
        />
    )
}

export function getColumns(type: TDiv10Type, isQuote: boolean, u: User, adjustName: (row: any, key: string) => any, proposing: boolean): {shift: number, columns: any} {

    switch (type) {
        case "wr-accessory":
            return {
                shift: isQuote ? 6 : 7,
                columns: [
                    ViewOnly(AdjustCol(StringCol("Product", "categoryName", 150), {
                        render: (d: PriceLine2) => {
                            return adjustName(d, "categoryName")
                        }
                    })),
                    ViewOnly(StringCol("Code", "productCode", 150)),
                    ViewOnly(StringCol("Description", "productName", 150)),
                    ViewOnly(StringCol("Manufacturer", "manufacturerName", 150)),
                    ViewOnly(StringCol("Finish", "finish", 150)),
                    ...commonPricingColumns(u, isQuote, proposing)
                ]
            }
        case "wr-partition":
            return {
                shift: isQuote ? 5 : 6,
                columns: [
                    ViewOnly(AdjustCol(StringCol("Mounting", "div10.partitionBracing", 150), {
                        render: (d: PriceLine2) => {
                            return adjustName(d, "div10.partitionBracing")
                        }
                    })),
                    ViewOnly(StringCol("Material", "div10.partitionCoating", 150)),
                    ViewOnly(StringCol("Description", "div10.description", 150)),
                    ViewOnly(StringCol("Manufacturer", "manufacturerName", 150)),
                    ...commonPricingColumns(u, isQuote, proposing)
                ]
            }
        case "locker":
            return {
                shift: isQuote ? 10 : 11,
                columns: [
                    ViewOnly(AdjustCol(StringCol("Tiers", "div10.lockerTiers", 150), {
                        render: (d: PriceLine2) => {
                            return adjustName(d, "div10.lockerTiers")
                        }
                    })),
                    ViewOnly(StringCol("Material", "div10.lockerCoating", 150)),
                    ViewOnly(BoolCol("Sloped Tops", "div10.lockerSloped", 60)),
                    ViewOnly(BoolCol("End Panels", "div10.lockerEndPanels", 60)),
                    ViewOnly(BoolCol("Base Trim", "div10.lockerBaseTrim", 60)),
                    ViewOnly(StringCol("Width", "dimWidth", 80)),
                    ViewOnly(StringCol("Depth", "dimLength", 80)),
                    ViewOnly(StringCol("Height", "dimHeight", 80)),
                    ViewOnly(StringCol("Manufacturer", "manufacturerName", 150)),
                    ...commonPricingColumns(u, isQuote, proposing)
                ]
            }
        case "mailbox":
            return {
                shift: isQuote ? 6 : 7,
                columns: [
                    ViewOnly(AdjustCol(StringCol("Tenant Units", "div10.mailboxTenantUnits", 150), {
                        render: (d: PriceLine2) => {
                            return adjustName(d, "div10.mailboxTenantUnits")
                        }
                    })),
                    ViewOnly(StringCol("Parcel Units", "div10.mailboxParcelUnits", 80)),
                    ViewOnly(StringCol("Loading", "div10.mailboxLoading", 100)),
                    ViewOnly(StringCol("Mounting", "div10.mailboxMounting", 100)),
                    ViewOnly(StringCol("Manufacturer", "manufacturerName", 150)),
                    ...commonPricingColumns(u, isQuote, proposing)
                ]
            }
        case "corner-guard":
            return {
                shift: isQuote ? 7 : 8,
                columns: [
                    ViewOnly(AdjustCol(StringCol("Type", "div10.cornerGuardType", 150), {
                        render: (d: PriceLine2) => {
                            return adjustName(d, "div10.cornerGuardType")
                        }
                    })),
                    ViewOnly(StringCol("Code", "productCode", 150)),
                    ViewOnly(StringCol("Finish", "div10.cornerGuardFinish", 60)),
                    ViewOnly(StringCol("Width", "dimWidth", 60)),
                    ViewOnly(StringCol("Height", "dimHeight", 60)),
                    ViewOnly(StringCol("Manufacturer", "manufacturerName", 150)),
                    ...commonPricingColumns(u, isQuote, proposing)
                ]
            }
        default:
            return {
                shift: 0,
                columns: commonPricingColumns(u, isQuote, proposing),
            };
    }
}
