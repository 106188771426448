import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {EventEmitter} from "nate-react-api-helpers";
export const PageRenderContext = createContext({
    AddPage: () => {
        return {
            id: 0,
            renderStart: () => {
                console.warn("invalid context")
            },
            renderComplete: (actualHeight: number, maxAllowed: number) => {
                console.warn("invalid context")
            },
            remove: () => {
                console.warn("invalid context")
            },
        };
    },
});

export function useSyncedRef<T>(input: T) {
    const ref = useRef(input);
    ref.current = input;
    return ref;
}

export function usePageInstance() {
    const ctx = useContext(PageRenderContext);

    const [page, setPage] = useState(() => ctx.AddPage());
    const lastPageRef = useSyncedRef(page)
    const addPage = useSyncedRef(ctx.AddPage)

    useEffect(() => {
        lastPageRef.current.remove();

        let pg = addPage.current()
        setPage(pg)

        return () => {
            pg.remove();
        }
    }, [lastPageRef, addPage]);

    return page;
}

export type DataSplitInfo<T> = {
    pageIndex: number;
    rows: T[];
    isLastPage: boolean;
}

class Page {
    id: number;
    hasRender: boolean = false;
    rendering: boolean = false;
    onRemove: () => void
    onRender: () => void

    shiftQty: number = 0;
    qtyAssigned: number = -1;

    constructor(seq: number,
                onRemove: () => void,
                onRender: () => void,
    ) {
        this.id = Date.now() * 1000 + seq;
        this.onRemove = onRemove;
        this.onRender = onRender;
    }

    renderStart() {
        this.rendering = true;
    }

    renderComplete(actualHeight: number, maxAllowed: number) {
        this.rendering = false;
        this.hasRender = true;
        this.shiftQty = 0;

        if(actualHeight <= maxAllowed) {
            this.onRender();
            return;
        }

        if(this.qtyAssigned === -1) {
            this.shiftQty = -1;
            this.onRender();
            return;
        }

        const estActualDataHeight = actualHeight - 400; // factor header/footers
        const estActualRowHeight = estActualDataHeight / this.qtyAssigned;
        const estDesiredDataHeight = maxAllowed - 400;
        const desiredQty = Math.floor(estDesiredDataHeight/estActualRowHeight) + 10; // over-assign so we shift down slowly at the end

        this.shiftQty = -(this.qtyAssigned - desiredQty);
        if(this.shiftQty >= 0) {
            this.shiftQty = -1;
        }

        this.onRender();
    }

    remove() {
        this.onRemove();
    }

    needsLess() {
        return this.shiftQty;
    }
}

enum State {
    INIT,
    NEW_DATA,
    SMOOTHING,
    READY
}

type PageDiv = {
    startIndex: number;
}

export function PageRenderProvider<T = any>(props: {
    data: T[];
    preferCombinedKey?: (input: T) => string | null;
    children: (input: DataSplitInfo<T>) => any
    onReady: (tf: boolean) => void;
}) {
    const list = useRef<Page[]>([]);
    const [state, setState] = useState(State.INIT);
    const [pageDivs, setPageDivs] = useState<PageDiv[]>([]);
    const renderEmitter = useMemo(() => new EventEmitter(), []);
    const onReady = useSyncedRef(props.onReady);

    const ctx = useMemo(() => {
        return {
            AddPage: () => {

                const p = new Page(list.current.length, () => {
                    console.log(p.id, "page removed")
                    list.current = list.current.filter(l => l !== p)
                }, () => renderEmitter.emit(null));

                console.log(p.id, "page created")
                list.current.push(p);
                return p;
            },
        }
    }, [renderEmitter]);

    const divRef = useSyncedRef(pageDivs);
    const dataRef = useSyncedRef(props.data);

    const updateQtyAssigned = useCallback((current: PageDiv[]) => {
        list.current.map((l, index) => {
            if(index+1 < current.length) {
                l.qtyAssigned = current[index + 1].startIndex - current[index].startIndex;
            } else if(index < current.length) {
                l.qtyAssigned = dataRef.current.length - current[index].startIndex;
            } else {
                l.qtyAssigned = 0;
            }

            return null;
        });

    }, [dataRef]);

    const shiftDivisions = useCallback((atIndex: number, shiftBy: number) => {
        console.log(`update divisions atIndex=${atIndex},by=${shiftBy}`, divRef.current.slice(0))
        if(shiftBy === 0) throw new Error("unexpected shift-by")

        let current = divRef.current.slice(0);
        if(atIndex > current.length) {
            throw new Error("invalid index");
        }

        if(atIndex + 1 === current.length) {
            current.push({
                startIndex: dataRef.current.length + shiftBy
            })

            updateQtyAssigned(current)
            setPageDivs(current);

            console.log("add new division", current)
            return current;
        }

        if(atIndex > current.length) {
            console.warn("last page is too big")
            return current;
        }

        for(let i = atIndex+1; i < current.length; i++) {
            if(current[i].startIndex + shiftBy <= current[i-1].startIndex) {
                throw new Error("invalid startIndex (your render chunk is too big and it's trying to put zero chunks on a page)");
            }

            current[i].startIndex += shiftBy;
        }

        updateQtyAssigned(current)
        setPageDivs(current);
        return current;
    }, [divRef, dataRef, updateQtyAssigned]);

    const currentProcessRejector = useRef((err: Error) => {});

    const pagesReady = useCallback((qty: number) => {
        list.current.map(p => {
            p.hasRender = false;
            return null;
        });

        return new Promise<void>((resolve, reject) => {
            currentProcessRejector.current = reject;

            const sub = renderEmitter.subscribeAndFireLast(() => {
                const doneCt = list.current.filter(l => l.hasRender).length

                if(doneCt >= qty) {
                    console.log("all pages ready")
                    clearTimeout(tm);
                    sub.cancel();
                    setTimeout(() => resolve(), 5);
                    return;
                }

                console.log(`${doneCt} of ${qty} pages ready`)
            });

            const tm = setTimeout(() => {
                sub.cancel();
                reject(new Error("timed out after 30s"));
            }, 30 * 1000);
        });
    }, [renderEmitter]);

    const prefKeyRef = useRef(props.preferCombinedKey)
    prefKeyRef.current = props.preferCombinedKey;

    const updateQtyForPref = useCallback((dataIndex: number, assigned: number, toRemove: number) => {
        // assigned less than zero means the page hasn't even been rendered yet
        if(assigned < 0) return toRemove;
        if(!prefKeyRef.current) return toRemove;

        let window = dataRef.current.slice(dataIndex, dataIndex + assigned + toRemove);
        if(window.length === 0) return toRemove;

        let addToRemove = 0;

        let lastPrefKey = prefKeyRef.current(window[window.length-1]);
        if(lastPrefKey === null) return toRemove;

        // remove items that are the same pref as lastPrefKey if possible
        while(window.length > 0) {
            let cur = window.pop();
            if(!cur) break;

            // if we hit a new pref key, leave that item in this page
            let curKey = prefKeyRef.current(cur);
            if(curKey !== lastPrefKey) {
                console.log("newpref", curKey, addToRemove)
                break;
            }

            // same preference, put it on the next page
            addToRemove++;
        }

        const revised = toRemove + (-addToRemove);

        // need to break preference b/c we can't remove everything off the page
        if(-revised === assigned) {
            return toRemove;
        }

        // remove additional items if necessary to help with preference
        return revised;

    }, [dataRef]);

    const processNewData = useCallback(async () => {
        let divs = [{
            startIndex: 0,
        }];
        divRef.current = divs;
        setPageDivs(divs);
        setState(State.SMOOTHING);
        let needsAdjusting = true;
        console.log("page-ctx: smoothing...", divs)

        while(needsAdjusting) {
            await pagesReady(divRef.current.length);

            needsAdjusting = false;
            let cur = list.current;

            let dataIndex = 0;

            for(let i = 0; i < cur.length; i++) {
                let qty = cur[i].needsLess();
                if(qty < 0 && cur[i].qtyAssigned !== 0) {
                    qty = updateQtyForPref(dataIndex, cur[i].qtyAssigned, qty)

                    divRef.current = shiftDivisions(i, qty);
                    needsAdjusting = true;
                    break;
                }

                dataIndex += cur[i].qtyAssigned;
            }
        }

        console.log("page-ctx: ready!", list.current.slice(0))
        setState(State.READY);
        onReady.current(true);
    }, [pagesReady, shiftDivisions, onReady, divRef, updateQtyForPref]);

    useEffect(() => {
        console.log("page-ctx: got new data, ready=false")
        setState(State.NEW_DATA);
        onReady.current(false)
    }, [props.data, onReady])

    useEffect(() => {
        switch(state) {
            case State.NEW_DATA:
                currentProcessRejector.current(new Error("cancelled for new data"));
                processNewData();
                break;
        }
    }, [state, processNewData])

    return (
        <PageRenderContext.Provider value={ctx}>
            <PageRenderCtrl<T> {...props} pageDivs={pageDivs} />
        </PageRenderContext.Provider>
    );
}

function PageRenderCtrl<T>(props: {
    data: T[];
    children: (input: DataSplitInfo<T>) => any
    pageDivs: PageDiv[];
}) {
    const sections = useMemo(() => {
        let list: DataSplitInfo<T>[] = [];

        for(let i = 0; i < props.pageDivs.length; i++) {
            const start = props.pageDivs[i].startIndex;

            if(i+1 < props.pageDivs.length) {
                const end = props.pageDivs[i+1].startIndex;

                list.push({
                    pageIndex: i,
                    rows: props.data.slice(start, end),
                    isLastPage: false,
                })
            } else {
                list.push({
                    pageIndex: i,
                    rows: props.data.slice(start),
                    isLastPage: true,
                })
            }
        }

        return list;
    }, [props.data, props.pageDivs])

    console.log("page-render", props.pageDivs.map(i => i.startIndex).join(","), sections.map(s => "length: " + s.rows.length));

    return (
        <>
            {sections.map((sect, index) =>
                <React.Fragment key={index}>{props.children(sect)}</React.Fragment>
            )}
        </>
    );
}

