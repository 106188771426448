import { HardwareItem, HwOpening} from "../../../../api/Hardware";
import {colCount, indentWidth} from "./HardwareTable";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Button, Divider, Grid, List, MenuItem} from "@mui/material";
import {IconButton2, IconCell} from "./library/IconCell";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {css} from "@emotion/css";
import {buildFilterLink} from "../../../../misc/scroller/Filter";
import {useProjectId} from "../ProjectName";
import {openingsTableName} from "../openings/Openings";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import {useReloader} from "./ReloadContext";
import {ProductSearchResult} from "../../../../api/Products";
import {ShortCode} from "../../../../api/Library";
import {ProductLookup} from "../extras/ProductLookup";
import {isShortCode, toProductQty} from "./PendingHardware";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {OpeningLookup} from "./OpeningLookup";
import {RenameHardwareGroup} from "./RenameHardwareGroup";
import {EditCell} from "./library/EditCell";
import {HWFilter, qtyWidth} from "./WorkingHardware";
import {EditProductContext} from "./EditProduct";
import {ProductDimensioned} from "./library/ProductDimensioned";
import {useHwAPI} from "./HardwareGeneric";
import {ChangesetContext} from "../changeset/ChangesetContext";
import {cellInActive} from "../pricing/cellconst";
import {AutoForHingeCell} from "./library/AutoForHingeCell";
import {StatusCell} from "./StatusCell";
import {EditHardwareGroupWithContent, EditHardwareItem, HwEditingContext} from "./HwEditingContextProvider";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {Opening} from "../../../../api/Openings";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {ResolveShortcodeChangesDialog} from "./ResolveShortcodeChangesDialog";
import {ConfirmShortcodeChange} from "../../../../api/QuoteHardware";
import {PopoutMenu} from "./library/PopoutMenu";
import {red} from "@mui/material/colors";
import {ConfirmContext} from "../../../../misc/Confirm";
import {useFocusMode} from "./UseFocusMode";
import {HardwareItemMenu} from "./HardwareItemMenu";
import {PrepManager} from "./prepCanvas/PrepManager";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {HardwareViewModeContext} from "../../quote/hardware/HardwareViewMode";

export const actionColWidth = 30;

export const HardwareGroup = React.memo(function HardwareGroup(props: {
    filter: HWFilter;
    hasFilter: boolean;
    value: EditHardwareGroupWithContent;
}) {
    const [value, setValue] = useState(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const editCtx = useContext(HwEditingContext);
    const editCtxRef = useSyncedRef(editCtx);
    // compare with props.value because our draft may edit "value"
    const isEditing = !!(editCtx.creating && editCtx.creating === props.value) || !!(editCtx.editing && editCtx.editing === props.value);
    const propsValueRef = useSyncedRef(props.value);

    const edit = useCallback((value: EditHardwareGroupWithContent) => {
        if(!editCtxRef.current.editing && !editCtxRef.current.creating) {
            editCtxRef.current.onEdit(propsValueRef.current);
        }

        setValue(value);
    }, [editCtxRef, propsValueRef]);
    
    const [collapseHeader, setCollapseHeader] = useState(false);

    const project = useProjectId();
    const reloadRef = useReloader();
    const hapi = useHwAPI();

    const [showRenameDialog, setShowRenameDialog] = useState(false);

    const filteredOpenings = props.filter.opening ? value.openings.filter(o => {
        return o.descriptiveName?.toLowerCase().indexOf(props.filter.opening.toLowerCase()) === 0;
    }) : value.openings;

    const shouldCollapse = value.openings.length > 5 && !isEditing;
    const hasOpeningFilter = !!props.filter.opening;

    useEffect(() => {
        setCollapseHeader(shouldCollapse && !hasOpeningFilter);
    }, [shouldCollapse, hasOpeningFilter]);

    const productEdit = useContext(EditProductContext);

    const hardwareFilter = props.hasFilter ? value.hardware.filter(h => {
        if(props.filter.code && h.productCode?.toLowerCase().indexOf(props.filter.code.toLowerCase()) !== 0) return false;
        if(props.filter.finish && h.finish?.toLowerCase().indexOf(props.filter.finish.toLowerCase()) !== 0) return false;
        if(props.filter.dimensions && h.dimensions?.toLowerCase().indexOf(props.filter.dimensions.toLowerCase()) !== 0) return false;
        if(props.filter.note && h.note?.toLowerCase().indexOf(props.filter.note.toLowerCase()) !== 0) return false;

        if(!h.productIsAutoQtyHinge) { // hinges don't have defined quantities
            if (props.filter.activeQty && h.activeQty?.toString() !== props.filter.activeQty) return false;
            if (props.filter.inactiveQty && h.inactiveQty?.toString() !== props.filter.inactiveQty) return false;
            if (props.filter.commonQty && h.commonQty?.toString() !== props.filter.commonQty) return false;
        }

        return true;
    }) : value.hardware;

    const changeset = useContext(ChangesetContext);
    const greyed = changeset.proposing && (!value.backup || value.name === value.backup?.name);

    const [headerRowRef, setHeaderRowRef] = useState<HTMLTableRowElement | null>(null);

    useFocusMode({
        enabled: isEditing,
        focusRef: headerRowRef,
        adjustFocus: (row: HTMLElement, retryLater): Element[] => {
            const table = row.parentElement;
            if(!table) {
                retryLater();
                return [];
            }

            const rows = Array.from(table.children);
            let hasStart = false;
            let filteredRows: Element[] = [];

            for(let i = 0; i < rows.length; i++) {
                if(rows[i] === headerRowRef) {
                    hasStart = true;
                    filteredRows.push(rows[i]);
                    continue
                }

                if(!hasStart) continue

                // @ts-ignore
                if(rows[i].dataset.headerGroup) {
                    hasStart = false;
                    continue
                }

                filteredRows.push(rows[i]);
            }

            if(filteredRows.length === 0) {
                console.log("no matching rows")
                return [];
            }

            return filteredRows;
        }
    });
    
    const editOnDoneRef = useSyncedRef(editCtx.onDone);
    const [showShortcodeDetail, setShowShortcodeDetail] = useState<ConfirmShortcodeChange|null>(null);
    const lastUpdateCallbackValue = useRef<EditHardwareGroupWithContent|null>(null);
    
    const update = useAsyncAction(async (input: {
        value: EditHardwareGroupWithContent
        createShortcode?: {
            name: string;
            description: string;
        };
        updateShortcode?: number;
        linkToShortcode?: number;
    }) => {
        const resp = await hapi.upsert(input);
        lastUpdateCallbackValue.current = input.value;
        
        if(resp.updated) {
            setShowShortcodeDetail(null)
            editOnDoneRef.current();
            reloadRef.current();
            return;
        }

        setShowShortcodeDetail(resp.confirmShortcodeChanges)
    }, []);

    const confirm = useContext(ConfirmContext);
    const [showHardwarePreps, setShowHardwarePreps] = useState(false);
    const previewChangeset = useContext(ChangePreviewContext);
    const viewMode = useContext(HardwareViewModeContext).viewMode;
    const locked = previewChangeset.enabled || viewMode !== "default";

    if(props.filter.opening && filteredOpenings.length === 0) return null;

    return (
        <>
            <tr key="group-name" data-header-group="true" ref={setHeaderRowRef}>
                <td style={{
                    width: actionColWidth,
                    backgroundColor: greyed ? cellInActive : undefined,
                }}></td>
                <td style={{
                    fontWeight: "500",
                    backgroundColor: greyed ? cellInActive : undefined,
                }} colSpan={colCount-1}>
                    {showShortcodeDetail && <ResolveShortcodeChangesDialog
                        value={showShortcodeDetail}
                        error={update.error}
                        onCancel={() => setShowShortcodeDetail(null)}
                        onDone={(input) => {
                            update.callback({
                                value: lastUpdateCallbackValue.current || value, // use last callback, b/c we may call update for a duplicate action which uses a different value than the "state" value.
                                createShortcode: input.createShortcode,
                                updateShortcode: input.updateShortcode,
                                linkToShortcode: input.linkToShortcode,
                            })
                        }} />}
                    <div style={{display: "flex", alignItems: "center", flexWrap: "nowrap"}}>
                        <div style={{flex: 1}}>
                            Heading {value.name || ""}
                        </div>
                        {!locked && <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", minWidth: 300, alignItems: "center"}}>
                            <IconButton2 style={{height: 16, marginRight: 10}}>
                                <AddCircleOutlineIcon fontSize="inherit" />
                            </IconButton2>
                            <OpeningLookup
                                backgroundColor="transparent"
                                ignore={value.openings.map(o => o.id)}
                                onSelect={o => {
                                    edit(Object.assign({}, value, {
                                        openings: value.openings.concat([toHwOpening(o)]),
                                    }));
                                }}
                                hwGroup={value.id}
                            />
                        </div>}
                        <div style={{display: "flex"}}>
                            {showRenameDialog && <RenameHardwareGroup value={value} onClose={() => setShowRenameDialog(false)} />}
                            {showHardwarePreps && <PrepManager
                                                        value={props.value as any}
                                                        onCancel={() => setShowHardwarePreps(false)}
                                                        onDone={async (config) => {

                                                            update.callback({
                                                                value: Object.assign({}, props.value, {
                                                                    prepConfig: config,
                                                                }),
                                                            })

                                                            setShowHardwarePreps(false)
                                                        }} />}
                            <Grid container spacing={1} alignItems="center">
                                {isEditing ? <>
                                        {!showShortcodeDetail && update.LoadingElement && <Grid item>{update.LoadingElement}</Grid>}
                                        <Grid item>
                                            <Button variant="outlined" color="primary" size="small" onClick={() => setShowRenameDialog(true)}>
                                                Rename
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" color="primary" size="small" onClick={() => {
                                                setValue(props.value);
                                                editCtx.onDone();
                                            }}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" size="small" onClick={() => {
                                                update.callback({value: value})
                                            }}>
                                                Save Changes
                                            </Button>
                                        </Grid>
                                    </>:
                                    <>
                                        <Grid item>
                                            <PopoutMenu size="small" disabled={locked}>
                                                {onClose => <>
                                                    <List>
                                                        <MenuItem onClick={() => {
                                                            setShowRenameDialog(true)
                                                            onClose();
                                                        }}>Rename</MenuItem>
                                                        <MenuItem onClick={() => {
                                                            update.callback({
                                                                value: Object.assign({}, value, {
                                                                    id: 0,
                                                                    openings: [],
                                                                    name: "",
                                                                })
                                                            })
                                                            onClose();
                                                        }}>Duplicate</MenuItem>
                                                        <MenuItem onClick={() => {
                                                            setShowHardwarePreps(true);
                                                            onClose();
                                                        }}>Customize Hardware Preps</MenuItem>
                                                        <Divider />
                                                        <MenuItem style={{color: red["800"]}} onClick={async () => {
                                                            onClose();
                                                            const ok = await confirm.confirm("Are you sure you want to remove this heading?")
                                                            if(!ok) return;

                                                            update.callback({
                                                                value: Object.assign({}, value, {
                                                                    archived: true,
                                                                })
                                                            })

                                                        }}>Remove</MenuItem>
                                                    </List>
                                                </>}
                                            </PopoutMenu>
                                        </Grid>
                                    </>}
                            </Grid>
                        </div>
                    </div>
                </td>
            </tr>

            {!collapseHeader && filteredOpenings.map(o =>
                <tr
                  key={"opening-" + o.id}
                  style={{
                      backgroundColor: (changeset.proposing || previewChangeset.enabled) && !o.sessionChanged ? cellInActive : undefined,
                  }}
            >
                <IconCell>
                    <IconButton2 disabled={locked} onClick={() => {
                        edit(Object.assign({}, value, {
                            openings: value.openings.filter(oo => oo.id !== o.id)
                        }));
                    }}>
                        <RemoveCircleOutline fontSize="inherit" />
                    </IconButton2>
                </IconCell>
                <td colSpan={colCount-2} style={{fontWeight: "500"}}>
                    {o.descriptiveName} {value.doorName} -- {o.handing}
                </td>
                <IconCell>
                    <a target="_blank" className={noStyleLink} href={`/project/${project}/shop-drawing/openings?${buildFilterLink({
                        tableName: openingsTableName(project),
                        filter: {
                            type: "eq",
                            search: {
                                id: o.id
                            },
                        }
                    })}`}>
                        <IconButton2>
                            <OpenInNewIcon fontSize="inherit" />
                        </IconButton2>
                    </a>
                </IconCell>
            </tr>)}
            {shouldCollapse && <tr key={"collapsed"} style={{backgroundColor: changeset.proposing ? cellInActive : undefined}}>
                <td></td>
                <td colSpan={colCount-1} style={{fontWeight: "500", paddingTop: 0, paddingBottom: 0}}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            {value.doorName}
                        </Grid>
                        <Grid item>
                            <i>({value.openings.length} openings)</i>
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={() => setCollapseHeader(!collapseHeader)}>{collapseHeader ? "Show": "Hide"}</Button>
                        </Grid>
                    </Grid>
                </td>
            </tr>}
            {hardwareFilter.filter(h => h.commonQty > 0 || h.activeQty > 0 || h.inactiveQty > 0).map((h, index) => {

                let defaultGrey = (changeset.proposing || previewChangeset.enabled) && (!h.backup || h.backup?.archived === false)
                const rowBg = defaultGrey ? cellInActive : undefined

                const checkBg = (key: keyof Omit<HardwareItem,"backup">) => {
                    if(!h.backup) return undefined;
                    const v = h as any as HardwareItem;
                    return defaultGrey && v.backup && v[key] !== v.backup?.[key] ? "white" : undefined
                }

                const updateHardwareItem = (key: keyof Omit<HardwareItem,"backup">, newValue: any) => {
                    edit(Object.assign({}, value, {
                        hardware: value.hardware.map((hh: EditHardwareItem) => {
                            if(hh.product === h.product) {
                                return Object.assign({}, hh, {
                                    [key]: newValue,
                                })
                            } else {
                                return hh;
                            }
                        }),
                    }))
                }

                const updateObj = (newValue: EditHardwareItem) => {
                    return edit(Object.assign({}, value, {
                        hardware: value.hardware.map((hh: EditHardwareItem) => {
                            if(hh.product === h.product) {
                                return newValue;
                            } else {
                                return hh;
                            }
                        }),
                    }))
                }

                return (<tr key={"hardware-" + index} style={{
                    backgroundColor: rowBg,
                    opacity: h.customerSupplied ? 0.5 : undefined,
                }}>
                    <IconCell>
                        <IconButton2 disabled={locked} onClick={() => {
                            updateObj(Object.assign({}, h, {
                                activeQty: 0,
                                inactiveQty: 0,
                                commonQty: 0,
                            }))
                        }}>
                            <RemoveCircleOutline fontSize="inherit"/>
                        </IconButton2>
                    </IconCell>
                    <td></td>
                    <td>
                        <span style={{borderBottom: "1px solid", cursor: "pointer"}}
                              onClick={() => productEdit.show(h.product)}>
                            {h.name}{h.isCustom ? " (Custom)" : null}
                        </span>
                    </td>
                    <td>{h.productCode}</td>
                    <td>{h.finish}</td>
                    <td>
                        <ProductDimensioned id={h.product} show={!!h.dimensionFormula} dimensions={h.calcByDimension} />
                    </td>
                    {h.productIsAutoQtyHinge ? <>
                        <AutoForHingeCell value={h} group={props.value} part="active" onOverride={value => {
                            updateObj(Object.assign({}, h, {
                                overrideHingeActiveQty: value,
                            }))

                            return Promise.resolve();
                        }} />
                        <AutoForHingeCell value={h} group={props.value} part="inactive" onOverride={value => {
                            updateObj(Object.assign({}, h, {
                                overrideHingeInActiveQty: value,
                            }))

                            return Promise.resolve();
                        }} />
                        <td style={{textAlign: "right"}}>-</td>
                    </> : <>
                        <EditCell disabled={locked} inputStyle={{width: qtyWidth}} value={h.activeQty.toString()}
                                  style={{
                                      textAlign: "right",
                                      backgroundColor: checkBg("activeQty")
                                  }}
                                  onChange={v => {
                                      updateHardwareItem("activeQty", parseInt(v))
                                  }}/>
                        <EditCell disabled={locked} inputStyle={{width: qtyWidth}} value={h.inactiveQty.toString()}
                                  style={{
                                      textAlign: "right",
                                      backgroundColor: checkBg("inactiveQty")
                                  }} onChange={v => {
                                    updateHardwareItem("inactiveQty", parseInt(v))
                                  }} />
                        <EditCell disabled={locked} inputStyle={{width: qtyWidth}} value={h.commonQty.toString()}
                                  style={{
                                      textAlign: "right",
                                      backgroundColor: checkBg("commonQty"),
                                  }}
                                  onChange={v => {
                                      updateHardwareItem("commonQty", parseInt(v))
                                  }}/>
                    </>
                    }
                    <StatusCell value={h} />
                    <EditCell disabled={locked} value={h.note} style={{
                        backgroundColor: checkBg("note"),
                    }} onChange={v => {
                        updateHardwareItem("note", v);
                    }} placeholder=""/>
                    <IconCell>
                        <HardwareItemMenu disabled={locked} value={h} onChange={updateObj} />
                    </IconCell>
                </tr>)
            })}
            {props.hasFilter || locked ? null : <tr style={{
                backgroundColor: changeset.proposing ? cellInActive : undefined,
            }}>
                <td />
                <td colSpan={colCount-1} style={{fontWeight: "500", paddingLeft: indentWidth-2}}>
                    <ProductLookup
                        disabled={locked}
                        backgroundColor="transparent"
                        placeholder="Enter Shortcode or start typing product name"
                        kind="hardware"
                        includeShortCodesForProject={project}
                        onSelect={async (result: ProductSearchResult | ShortCode) => {
                            const openingType = props.value.openings.map(o => o.openingType).find(v => !!v);

                            if(isShortCode(result)) {
                                edit(Object.assign({}, value, {
                                    hardware: toProductQty(result, openingType)
                                }));
                            } else {
                                const update = value.hardware.slice(0)
                                const toAdd = toProductQty(result, openingType);
                                toAdd.map(a => {
                                    const existing = update.find(u => u.product === a.product);
                                    if(existing) {
                                        existing.activeQty += a.activeQty;
                                        existing.inactiveQty += a.inactiveQty;
                                        existing.commonQty += a.commonQty;
                                        return null;
                                    }

                                    update.push(a)
                                    return null;
                                })

                                edit(Object.assign({}, value, {
                                    hardware: update,
                                }));
                            }
                        }}
                    />
                </td>
            </tr>}
        </>
    )
});

export const noStyleLink = css({
    color: "inherit",
    textDecoration: "none",
})

export function toHwOpening(input: QuoteOpening|Opening): HwOpening {
    return {
        seqNumber: input.seqNumber,
        name: input.name,
        descriptiveName: input.name,
        handing: input.handing,
        id: input.id,
        sessionChanged: true,
        nominalHeight: input.nominalHeight,
        nominalWidth: input.nominalWidth,
        inactiveDoorWidth: input.inactiveDoorWidth,
        screenElevation: input.screenElevation,
        openingType: input.openingType,
    }
}