import {hasPermission, useUser} from "../../../../misc/Permission";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {
    AdjustCol,
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {priceTableName} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {commonGroupBy, commonPricingColumns, PriceLine2, proposingGroupBy} from "./Frames";
import {priceDetailEmitter} from "./UnPricedItems";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";

export function Hardware() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const proposal = useProposingKit();
    const previewChangeset = useContext(ChangePreviewContext);

    if(!u) return null;

    return (
        <Table<PriceLine2>
            name={priceTableName(project, "hardware")}
            globalPrefsName={priceTableName(0, "hardware")}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            cellCustomize={proposal.cellCustomize}
            fetch={async (ctx) => {
                const rs = await getAll(ctx, offset => api.pricing.list({
                    project: project,
                    offset,
                    previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined,
                }))
                const rows = proposal.transformRows(rs)

                priceDetailEmitter.emit(rows);
                return rows;
            }}
            sort={(a, b) => {
                const bigNumber = 1000000;
                if(a.openingName && b.openingName) {
                    if (a.openingName < b.openingName) return -1;
                    if (a.openingName > b.openingName) return 1;
                }

                return (a.categorySortOrder || bigNumber) - (b.categorySortOrder || bigNumber)
            }}
            columns={[
                {
                    name: "",
                    render: o => proposal.rowAction(o, "hardware", ""),
                    width: 150,
                },
                proposal.proposing && ViewOnly(StringCol("Opening", "openingName", 150)),
                ViewOnly(AdjustCol(StringCol("Name", "productName", 150), {
                    render: (d: PriceLine2) => {
                        return proposal.renderItemName(d, "productName")
                    }
                })),
                ViewOnly(StringCol("Code", "productCode", 80)),
                ViewOnly(StringCol("Finish", "finish", 80)),
                ViewOnly(StringCol("Length", "dimLength", 50)),
                ViewOnly(StringCol("Width", "dimWidth", 50)),
                ViewOnly(StringCol("Height", "dimHeight", 50)),
                ...proposal.modifyColumns(commonPricingColumns(u, false, proposal.proposing) as any),
            ]}
            fetchDeps={[project, proposal.proposing, proposal.expanded, previewChangeset.enabled, previewChangeset.sessionId]}
            columnDeps={[proposal.proposing, u]}
            groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy : commonGroupBy, {
                groupFilter: (groupName: string) =>
                    groupName.startsWith("hardware") || groupName === "project",
            })}
            onChange={(input) => api.pricing.upsert(proposal.processUpdate(input))}
        />
    )
}
