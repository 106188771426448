import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {getAll} from "../../shopdrawing/openings/Openings";
import {api} from "../../../../api/API";
import {AutoComplete} from "../../shopdrawing/openings/AutoComplete";
import {Opening, DoorElevation} from "../../../../api/Openings";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {useFilePicker} from "../../shopdrawing/extras/FilePicker";
import {useAsyncAction} from "nate-react-api-helpers";
import useWindowSize from "react-use/lib/useWindowSize";

export function DoorElevationPopup<T extends (Opening)>(props: {
    anchor: any;
    row: T;
    width: number;
    initialValue: string;
    onCancel(): void;
    onDone(value: T): Promise<any>;
}) {
    const filePicker = useFilePicker({
        accept: ["application/pdf"]
    });

    const [name, setName] = useState(props.row.doorElevation);
    const [file, setFile] = useState(props.row.doorElevationFile ? {name: props.row.doorElevationFileName, id: props.row.doorElevationFile} : null);
    const projectId = useProjectId();

    const onDoneRef = useSyncedRef(props.onDone);
    const doneCb = useAsyncAction(async (input) => {
        await onDoneRef.current(input)
    }, []);

    const upload = useAsyncAction(async (file: File) => {
        const fileObj = await api.files.upload({
            file: file,
        });

        setFile({
            id: fileObj.id,
            name: file.name,
        });
    }, [filePicker]);

    const size = useWindowSize();

    return (
        <Dialog open fullWidth maxWidth="lg">
            <form onSubmit={e => {
                e.preventDefault()

                doneCb.callback(Object.assign({}, props.row, {
                    doorElevation: name,
                    doorElevationFile: file ? file.id : -1,
                }))
            }}>

                <DialogTitle>Door Elevation</DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            {file ? <iframe
                                src={api.files.downloadLink(file.id, "inline")}
                                style={{
                                    height: size.height * 0.75,
                                    width: "100%",
                                    border: "1px solid black",
                                }}
                            ></iframe> : <div>Choose a file on the right</div>}
                        </Grid>
                        <Grid item xs={4}>
                            <AutoComplete<DoorElevation>
                                fullWidth
                                label="Name"
                                size="small"
                                search={async input => {

                                    let result = await getAll(null, offset =>
                                        api.openings.listDoorElevations({
                                            project: projectId,
                                            offset: offset,
                                        }))

                                    if(input !== "") {
                                        input = input.toLowerCase();
                                        result = result.filter(r => r.name.toLowerCase().indexOf(input) !== -1)
                                    }

                                    return result;
                                }}
                                value={{name: name, fileId: file}}
                                equals={(a, b) => a.name === b.name}
                                makeLabel={a => a.name}
                                onSelect={value => {
                                    setFile(value.file ? {
                                        id: value.file,
                                        name: value.fileName || "",
                                    } : null);

                                    setName(value.name);
                                }}
                                onCreate={async name => {
                                    setName(name);
                                    setFile(null);
                                }}
                            />

                            <div style={{height: 24}} />

                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                    {file ? file.name : "No file selected"}
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" size="small" onClick={async () => {
                                        const files = await filePicker();
                                        upload.callback(files[0])
                                    }}>
                                        Choose File
                                    </Button>
                                </Grid>
                                {file && <Grid item onClick={() => setFile(null)}>
                                    <Button variant="outlined" size="small">
                                        Clear File
                                    </Button>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {doneCb.LoadingElement}
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button type="submit">Done</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}