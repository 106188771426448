import {hasPermission, useUser} from "../../../../misc/Permission";
import {api} from "../../../../api/API";
import {
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import React, {useContext, useEffect} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {priceDetailEmitter} from "../../shopdrawing/pricing/UnPricedItems";
import {getAll} from "../../shopdrawing/openings/Openings";
import {commonGroupBy, commonPricingColumns} from "../../shopdrawing/pricing/Frames";
import {QuotePriceLine} from "../../../../api/QuotePricing";

export function QuoteHardware() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative();
    const customize = usePriceCellCustomize();

    useEffect(() => {
     console.log("render hardware")
    });

    if(!u) return null;

    return (
        <Table<QuotePriceLine>
            name={priceTableName(project, "hardware")}
            globalPrefsName={priceTableName(0, "hardware")}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            cellCustomize={customize}
            fetch={async (ctx) => {
                const rs = await getAll(ctx, offset => api.quotePricing.list({project: project, alternative, offset}))
                priceDetailEmitter.emit(rs);
                return rs;
            }}
            sort={(a, b) => {
                const bigNumber = 1000000;
                return (a.categorySortOrder || bigNumber) - (b.categorySortOrder || bigNumber)
            }}
            columns={[
                ViewOnly(StringCol("Name", "productName", 150)),
                ViewOnly(StringCol("Code", "productCode", 80)),
                ViewOnly(StringCol("Finish", "finish", 80)),
                ViewOnly(StringCol("Length", "dimLength", 50)),
                ViewOnly(StringCol("Width", "dimWidth", 50)),
                ViewOnly(StringCol("Height", "dimHeight", 50)),
                ...commonPricingColumns(u, true),
            ]}
            fetchDeps={[project, alternative]}
            columnDeps={[u]}
            groupBy={Object.assign({}, commonGroupBy, {
                groupFilter: (groupName: string) =>
                    groupName.startsWith( "hardware") || groupName === "project",
            })}
            onChange={(input) => api.quotePricing.upsert(input)}
        />
    )
}
