
import {Button, Grid, Popover, TextField, Typography} from "@mui/material";
import {formatCents, parseCents, useAsyncAction} from "nate-react-api-helpers";
import {useRef, useState} from "react";
import {PriceLine2} from "../Frames";
import {formatMarkup, tableCls} from "./Removal";
import {parseMarkup} from "../Pricing";

export function Addition(props: {
    value: PriceLine2;
    onDone(update: PriceLine2): Promise<void>
    onCancel(): void;
    anchor: any;
}) {
    const v = props.value.kind === "current" ? props.value : null;
    const [total, setTotal] = useState(formatCents(v?.initial.extendedPrice || 0));
    const [unitCostStr, setUnitCostStr] = useState(formatCents(v?.initial.unitCostCents || 0));
    const [markupStr, setMarkupStr] = useState(v?.initial.markupCents ? formatCents(v?.initial.markupCents || 0) : (v?.initial.markupPercent || 0) + "%");

    const onDoneRef = useRef(props.onDone);
    onDoneRef.current = props.onDone;

    const action = useAsyncAction((input) => onDoneRef.current(input), []);
    if(v === null) return null;

    const unitCostCents = parseCents(unitCostStr);
    const totalC = parseCents(total);
    const mark = parseMarkup(markupStr);
    const qty = props.value.qty;

    return (
        <Popover open={true} anchorEl={props.anchor} onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault();

                const mark = parseMarkup(markupStr);

                action.callback(Object.assign({}, props.value, {
                    extendedPrice: totalC,
                    unitCostCents: unitCostCents,
                    markupCents: mark.markupCents,
                    markupPercent: mark.markupPercent,
                }));
            }}>
                <div style={{padding: 16, paddingBottom: 8}}>
                    <Typography style={{fontSize: "0.9rem", fontWeight: "700"}}>New</Typography>
                </div>

                <table className={tableCls}>
                    <tbody>
                    <tr>
                        <th >Unit Cost</th>
                        <td>{formatCents(unitCostCents)}</td>
                    </tr>
                    <tr>
                        <th >Qty</th>
                        <td>{v.initial.qty}</td>
                    </tr>
                    <tr>
                        <th >Markup</th>
                        <td>{formatMarkup({
                            cents: mark.markupCents,
                            percent: mark.markupPercent,
                            basePrice: v.initial.qty * unitCostCents,
                        })}</td>
                    </tr>
                    <tr>
                        <th >Total</th>
                        <td>{formatCents(totalC)}</td>
                    </tr>
                    </tbody>
                </table>

                <div style={{padding: 16}}>
                    <TextField
                        label="Unit Cost"
                        value={unitCostStr}
                        style={{minWidth: 100}}
                        onChange={e => {
                            setUnitCostStr(e.target.value)

                            const unitCostCents = parseCents(e.target.value);

                            const mk = parseMarkup(markupStr)
                            setTotal(formatCents(calcTotal(unitCostCents, qty, mk)))
                        }}
                        fullWidth
                    />

                    <div style={{height: 16}} />

                    <TextField
                        label="Markup"
                        value={markupStr}
                        style={{minWidth: 100}}
                        onChange={e => {
                            const value = e.target.value;
                            setMarkupStr(value)

                            const mk = parseMarkup(value)
                            console.log("markup")
                            setTotal(formatCents(calcTotal(unitCostCents, qty, mk)))
                        }}
                        fullWidth
                    />

                    <div style={{height: 16}} />

                    <TextField
                        label="Total"
                        value={total}
                        style={{minWidth: 100}}
                        onChange={e => {
                            setTotal(e.target.value)
                            const totalC = parseCents(e.target.value)
                            setMarkupStr("$" + formatCents(totalC - unitCostCents * qty))
                        }}
                        fullWidth
                    />
                </div>
                <div style={{padding: 8}}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            {action.LoadingElement}
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={props.onCancel}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" type="submit">Save</Button>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </Popover>
    )
}

export function calcTotal(unitCostCents: number, qty: number, markup: {
    markupCents: number;
    markupPercent: null;
} | {
    markupCents: null;
    markupPercent: number;
} | {
    markupCents: null;
    markupPercent: null;
}) {
    const c = unitCostCents * qty
    if(markup.markupCents !== null) return (unitCostCents + markup.markupCents) * qty;
    if(markup.markupPercent !== null) return Math.round(c * (1 + markup.markupPercent / 100));
    return c;
}