
import {Button, Grid, Popover, TextField, Typography} from "@mui/material";
import {formatCents, parseCents, useAsyncAction} from "nate-react-api-helpers";
import {useRef, useState} from "react";
import {PriceLine2} from "../Frames";
import {formatMarkup, tableCls} from "./Removal";
import {grey} from "@mui/material/colors";
import {parseMarkup} from "../Pricing";
import {calcTotal} from "./Addition";

export function Replace(props: {
    value: PriceLine2;
    onDone(update: PriceLine2): Promise<void>
    onCancel(): void;
    anchor: any;
}) {
    const v = props.value.kind === "current" ? props.value : null;

    const [total, setTotal] = useState(formatCents(v?.initial.extendedPrice || 0));
    const [unitCostStr, setUnitCostStr] = useState(formatCents(v?.initial.unitCostCents || 0));
    const [markupStr, setMarkupStr] = useState(v?.initial.markupCents ? formatCents(v?.initial.markupCents || 0) : (v?.initial.markupPercent || 0) + "%");

    const onDoneRef = useRef(props.onDone);
    onDoneRef.current = props.onDone;

    const action = useAsyncAction((input) => onDoneRef.current(input), []);
    if(v === null) return null;

    const unitCostCents = parseCents(unitCostStr);
    const totalC = parseCents(total);
    const mark = parseMarkup(markupStr);
    const qty = props.value.qty;
    const newQty = v.initial.qty
    const refundAmount = v.previous.extendedPrice;
    const newTotalC = totalC + refundAmount;

    return (
        <Popover open={true} anchorEl={props.anchor} onClose={props.onCancel}>
            <form onSubmit={e => {
                e.preventDefault();

                action.callback(Object.assign({}, props.value, {
                    extendedPrice: newTotalC,
                    unitCostCents: (unitCostCents + (v?.previous.unitCostCents || 0)),
                }));
            }}>
                <div style={{padding: 16, paddingBottom: 8}}>
                    <Typography style={{fontSize: "0.9rem", fontWeight: "700"}}>Replace</Typography>
                </div>

                <table className={tableCls}>
                    <tbody>
                    <tr>
                        <th rowSpan={5} style={{
                            writingMode: "vertical-lr", paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                            borderRight: "1px solid " + grey["200"]
                        }}>
                            Old Item
                        </th>
                        <th style={{paddingLeft: 4}}>Unit Cost</th>
                        <td>{-formatCents(v.previous.unitCostCents)}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th style={{paddingLeft: 4}}>Qty</th>
                        <td>{v.previous.qty}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th style={{paddingLeft: 4}}>Markup</th>
                        <td>{formatMarkup({
                            cents: v.previous.markupCents,
                            percent: v.previous.markupPercent,
                            basePrice: -v.previous.qty * v.previous.unitCostCents,
                        })}</td>
                        <td></td>
                    </tr><tr>
                        <th style={{paddingLeft: 4}}>Total</th>
                        <td></td>
                        <td>{formatCents(-v.previous.extendedPrice)}</td>
                    </tr><tr>
                        <th style={{paddingLeft: 4}}>Refunded</th>
                        <td></td>
                        <td>{formatCents(refundAmount)}</td>
                    </tr>
                    <tr>
                        <th rowSpan={5} style={{
                            writingMode: "vertical-lr", paddingTop: 8, paddingBottom: 8, paddingRight: 8,
                            borderRight: "1px solid " + grey["200"]
                        }}>
                            New Item
                        </th>
                        <th style={{paddingLeft: 4}}>Unit Cost</th>
                        <td>{formatCents(unitCostCents)}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th style={{paddingLeft: 4}}>Qty</th>
                        <td>{newQty}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th style={{paddingLeft: 4}}>Markup</th>
                        <td>{formatMarkup({
                            cents: mark.markupCents,
                            percent: mark.markupPercent,
                            basePrice: newQty * unitCostCents,
                        })}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th style={{paddingLeft: 4}}>Amount</th>
                        <td></td>
                        <td>{formatCents(totalC)}</td>
                    </tr>
                    <tr>
                        <th style={{paddingLeft: 4}}>New Total ({newTotalC > 0 ? "extra" : "credit"})</th>
                        <td></td>
                        <td>{formatCents(newTotalC)}</td>
                    </tr>
                    </tbody>
                </table>

                <div style={{padding: 16}}>
                    <TextField
                        label="Unit Cost"
                        value={unitCostStr}
                        style={{minWidth: 100}}
                        onChange={e => {
                            setUnitCostStr(e.target.value)

                            const unitCostCents = parseCents(e.target.value);

                            const mk = parseMarkup(markupStr)
                            setTotal(formatCents(calcTotal(unitCostCents, qty, mk)))
                        }}
                        fullWidth
                    />

                    <div style={{height: 16}} />

                    <TextField
                        label="Markup"
                        value={markupStr}
                        style={{minWidth: 100}}
                        onChange={e => {
                            const value = e.target.value;
                            setMarkupStr(value)

                            const mk = parseMarkup(value)
                            console.log("markup")
                            setTotal(formatCents(calcTotal(unitCostCents, qty, mk)))
                        }}
                        fullWidth
                    />

                    <div style={{height: 16}} />

                    <TextField
                        label="Amount"
                        value={total}
                        style={{minWidth: 100}}
                        onChange={e => {
                            setTotal(e.target.value)
                            const totalC = parseCents(e.target.value)
                            setMarkupStr("$" + formatCents(totalC - unitCostCents * qty))
                        }}
                        fullWidth
                    />
                </div>
                <div style={{padding: 8}}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            {action.LoadingElement}
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={props.onCancel}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button size="small" type="submit">Save</Button>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </Popover>
    )
}