import {Opening} from "../../../../../api/Openings";
import {ExportColumn} from "../MakeSubmittal";
import React, {PropsWithChildren, useCallback, useEffect, useRef} from "react";
import {PageRenderProvider} from "../../../../../misc/pdf/parts/PageContext";
import {pdfTableStyle} from "../../../../../misc/pdf/parts/Page";
import {css} from "@emotion/css";
import {ContainerPage} from "../../../../../misc/pdf/parts/ContainerPage";
import {useReadyCheck} from "../../../../../misc/pdf/UseReadyCheck";
import {QuoteOpening} from "../../../../../api/QuoteOpenings";

export function OpeningsPage(props: {
    data: (QuoteOpening | Opening)[]
    columns: ExportColumn[]
    onReady(): void;
}) {

    const ready = useReadyCheck(props.onReady);

    return (<>
        <PageRenderProvider data={props.data} onReady={ready.section()}>
            {(info)=> {
                return (<ContainerPage>
                    <AutoShrinkTable>
                        <thead>
                        <tr>
                            {props.columns.map((c, index) => <th key={index.toString()} style={{fontSize: "8px", lineBreak: "anywhere"}}>{c.display.split("\n").map(v => <>{v}<br /></>)}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {info.rows.map((i, index) => {
                            return (
                                <tr key={index.toString()}>
                                    {props.columns.map((c, cindex) => {
                                        if(c.render) {
                                            return <td key={cindex.toString()} style={{lineBreak: "anywhere", paddingBottom: 4, paddingTop: 4}}>{c.render(i)}</td>
                                        }

                                        // @ts-ignore
                                        return <td key={cindex.toString()} style={{lineBreak: "anywhere", paddingBottom: 4, paddingTop: 4}}>{i[c.key]}</td>
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </AutoShrinkTable>
                </ContainerPage>)
            }}
        </PageRenderProvider>
        </>)
}

function AutoShrinkTable(props: PropsWithChildren<{ }>) {
    const tableRef = useRef<HTMLTableElement|null>(null);

    const updateHeight = useCallback(() => {
        const t = tableRef.current;
        if(!t) return;

        t.style.marginBottom = ""

        const hSmall = t.getBoundingClientRect().height;

        t.style.transform = "none"
        const hBig = t.getBoundingClientRect().height;

        t.style.transform = ""
        t.style.marginBottom = -(hBig - hSmall)/2 + "px";
        t.style.marginTop = -(hBig - hSmall)/2 + "px";

    }, []);

    useEffect(() => {
        updateHeight();
    });

    return (
        <table className={pdfTableStyle + " " + shrinkOnRender} style={{fontSize: "10px"}} ref={r => {
            tableRef.current = r;

            if(r) {
                updateHeight()
            }
        }}>
            {props.children}
        </table>
    )
}

const shrinkOnRender = css(`
@media print {
    width: 133%;
    transform: scale(0.75) translateX(-16.6%);
}

width: 133%;
transform: scale(0.75);
`)