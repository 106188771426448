import {useProjectId, useProjectUnitSystem} from "../../shopdrawing/ProjectName";
import React, {useContext, useState} from "react";
import {useUser} from "../../../../misc/Permission";
import {QuoteOpening} from "../../../../api/QuoteOpenings";
import {
    AdjustCol, Column,
    DistanceCol, imperialInchParser, keyMatch, lookupNestedProp, NestedKeyOf, NominalWidth,
    NumberCol,
    Select2Col, SelectCol, SelectInput, SeqNumber, sortString,
    StringCol,
    Table, TID
} from "../../../../misc/scroller/Table";
import {getAll} from "../../shopdrawing/openings/Openings";
import {QuoteContainer} from "../QuoteContainer";
import {AutoInsertOpening} from "../../shopdrawing/openings/AutoInsertOpening";
import {api} from "../../../../api/API";
import {EditHardwareGroup} from "../../shopdrawing/openings/EditHardwareGroup";
import {RowActions} from "./RowActions";
import {ImperialDistance} from "../../../../misc/ImperialDistance";
import {Grid, Tab, Tabs} from "@mui/material";
import {TabWrapper} from "../../shopdrawing/release/Release";
import {useQueryParam} from "../pricing/QueryParam";
import {OpeningSwitcher} from "../../shopdrawing/openings/OpeningSwitcher";
import {first} from "nate-react-api-helpers";
import {useAlternative} from "../alternative/Alternative";
import {PrepLocations} from "./PrepLocations";
import {Opening} from "../../../../api/Openings";
import {ProductCol} from "../../shopdrawing/div10/Div10Table";
import {PrepDetail} from "../../../../api/QuoteHardware";
import {TextEditCell} from "./TextEditCell";
import {ScreenElevationPopup} from "./ScreenElevationPopup";
import {NewRowMenu} from "./NewRowMenu";
import {BreakHardwareLinkContext, BreakHardwareLinkProvider} from "./BreakHardwareLinkProvider";
import {MakeQuoteSubmittal} from "../../shopdrawing/openings/MakeSubmittal";
import {QuoteSubmittalHistory} from "./QuoteSubmittalHistory";
import {AnchorQty} from "./AnchorQty";
import {UnitSystem} from "../../../../api/Projects";
import {projectTablePrefName} from "../../shopdrawing/TablePrefName";

export const woodDoor = "WD Door"

export function quoteOpeningsTableName(project: number, tab = "all") {
    return projectTablePrefName("project.quoteOpenings." + tab, project)
}

export function QuoteOpenings() {
    const [tab, setTab] = useQueryParam("tab", "doors");

    const u = useUser();
    if(!u) return null;

    return <QuoteContainer name={<OpeningSwitcher />} actions={
        <div>
            <Grid container spacing={1}>
                <Grid item>
                    <MakeQuoteSubmittal />
                </Grid>
                <Grid item>
                    <QuoteSubmittalHistory />
                </Grid>
            </Grid>
        </div>
    }>
        <div style={{
            padding: 0,
            flex: 1, display: "flex",
            flexDirection: "column",
            overflow: "auto"
        }}>
            <TabWrapper>
                <Tabs value={tab} onChange={(e, value) => {
                    setTab(value)
                }}>
                    <Tab label="Frames" value="frames" />
                    <Tab label="Doors" value="doors" />
                    <Tab label="All" value="all" />
                </Tabs>
            </TabWrapper>

            <BreakHardwareLinkProvider>
                <QuoteOpeningTable tab={tab} />
            </BreakHardwareLinkProvider>
        </div>
    </QuoteContainer>
}

export let currentQuoteTab = "";

function QuoteOpeningTable(props: {
    tab: string;
}) {
    const {tab} = props;
    const project = useProjectId();
    const alternative = useAlternative();
    const showDoor = (tab === "doors" || tab === "all");
    const showFrame = (tab === "frames" || tab === "all");
    const tableName = quoteOpeningsTableName(project, tab);
    const tableName2 = quoteOpeningsTableName(0, tab);
    const [key] = useState("-")
    const hwContext = useContext(BreakHardwareLinkContext);

    currentQuoteTab = tab;

    const sys = useProjectUnitSystem();
    console.log("sys", sys)
    if(!sys) return null;

    return (
        <Table<QuoteOpening>
            key={key + tab}
            locked={false}
            name={tableName}
            globalPrefsName={tableName2}
            onDrag={async (input) => {
                await api.quoteOpenings.reOrder({
                    project: project,
                    alternative: alternative,
                    idOrder: input.idOrder,
                })

                return {
                    sortByCol: null
                }
            }}
            columns={[{
                name: "",
                render: o => <RowActions row={o} />,
                width: 200,
            },
                SeqNumber("seqNumber", tableName, (ids) => api.quoteOpenings.reSequence({
                    project: project,
                    alternative: alternative,
                    idOrder:ids,
                })),
                StringCol("Opening", "name"),
                NumberCol("Qty", "qty"),
                StringCol("Floor", "floor"),
                StringCol("Location", "locationOne"),
                Select2Col<QuoteOpening>("To/From", "locationTransition", locationTransitions),
                StringCol<QuoteOpening>("Location 2", "locationTwo"),
                Select2Col<QuoteOpening>("Type", "openingType", openingTypes),
                NominalWidth<QuoteOpening>("Nominal Width", sys, "nominalWidth", "inactiveDoorWidth"),
                DistanceCol("Nominal Height", sys, "nominalHeight"),
                AdjustCol(StringCol("Hand", "handing"), {
                    editable: {
                        type: "select",
                        options: handingOptions as any,
                        onChangeBeforeSave: (row: QuoteOpening, value: string) => {
                            updateFrameProfileBasedOnHanding(row)
                        }
                    },
                    disabled: isWindow as any,
                }),
                Select2Col<QuoteOpening>("Label", "label", labels),
                showFrame && Select2Col<QuoteOpening>("Frame Series", "frameSeries", frameSeries),
                showFrame && SelectCol<QuoteOpening>("Frame Material", "frameMaterial", frameMaterial),
                showFrame && AdjustCol(SelectCol<QuoteOpening>("Frame Gauge", "frameGauge", frameGauge), {
                    alignRight: true,
                }),
                showFrame && {
                    name: "Screen Elevation",
                    editable: {
                        type: "custom",
                        copy: data => data.screenElevation?.toString() || "",
                        paste: (data, value) => {
                            data.screenElevation = value;
                        },
                        render: (props) => {
                            if(!props.row.screenElevation) {
                                return (
                                    <TextEditCell keyName="screenElevation" {...props} />
                                )
                            }

                            return <ScreenElevationPopup {...props} />
                        }
                    },
                    editObj: (dst, src, value: string) => {
                        dst.screenElevation = value;
                        dst.screenElevationFile = undefined;
                    },
                    render: (data, col) => {
                        return data.screenElevation || "";
                    }
                },
                showFrame && SelectCol<QuoteOpening>("Frame Profile", "frameProfile", frameProfile),
                showFrame && JambDepthCol("Jamb Depth", "jambDepth"),
                showFrame && SelectCol<QuoteOpening>("Frame Construction", "frameConstruction", frameConstruction),
                showFrame && AnchorCol(),
                showDoor && AdjustCol(Select2Col<QuoteOpening>("Door Series", "doorSeries", doorSeries), {
                    disabled: isWindow as any,
                }),
                showDoor && AdjustCol(SelectCol<QuoteOpening>("Door Material", "doorMaterial", doorMaterial), {
                    disabled: isWindow as any,
                }),
                showDoor && AdjustCol(SelectCol<QuoteOpening>("Door Finish", "doorFinish", doorFinish, {
                    freeSolo: true
                }), {
                    disabled: isWindow as any,
                }),
                showDoor && AdjustCol(SelectCol<QuoteOpening>("Door Gauge", "doorGauge", doorGauge), {
                    alignRight: true,
                    disabled: isWindow as any,
                }),
                showDoor && AdjustCol(Select2Col<QuoteOpening>("Door Thickness", "doorThickness", doorThicknesses(sys)), {
                    disabled: isWindow as any,
                }),
                showDoor && AdjustCol(SelectCol<QuoteOpening>("Door Elevation", "doorElevation", doorElevations), {
                    disabled: isWindow as any,
                }),
                showDoor && AdjustCol(SelectCol<QuoteOpening>("Door Core", "doorCore", doorCore), {
                    disabled: isWindow as any,
                }),
                showDoor && AdjustCol(SelectCol<QuoteOpening>("Door Seam", "doorSeam", doorSeam), {
                    disabled: isWindow as any,
                }),
                AdjustCol(PrepLocationCol<QuoteOpening>(), {
                    disabled: isWindow as any,
                }),
                {
                    name: "Hardware Heading",
                    width: 200,
                    render: (data, col) => {
                        return data.hardwareGroupName || ""
                    },
                    editable: {
                        type: "button",
                        render: props => <EditHardwareGroup isQuote {...props} />
                    },
                    filter: (data, search) => {
                        return data.hardwareGroupName?.indexOf(search) === 0
                    },
                    disabled: isWindow as any,
                },
            ]}
            fetch={ctx => getAll(ctx, offset => api.quoteOpenings.list({
                project: project, offset,
                alternative: alternative,
            }))}
            fetchDeps={[project, alternative]}
            insert={{
                buttonText: "New Row",
                alignX: "left",
                modal: input => <AutoInsertOpening onDone={input} />,
                options: () => <NewRowMenu />,
            }}
            onChange={async (input) => {
                try {
                    return await api.quoteOpenings.upsert(input)
                } catch (e: any) {
                    return await hwContext.onError(input, e, async (input) => {
                        return await api.quoteOpenings.upsert(input)
                    });
                }
            }}
        />
    )
}

export function updateFrameProfileBasedOnHanding<T extends (Opening|QuoteOpening)>(row: T) {
    if(row.frameProfile) return;

    switch(row.handing) {
        case "LH-DA":
        case "RH-DA":
        case "CO":
        case "LH-BARN":
        case "RH-BARN":
        case "LH-BIFOLD":
        case "RH-BIFOLD":
        case "LHA-DA":
        case "RHA-DA":
        case "LHA/RHA-DA":
        case "DBL BYPASS":
        case "DBL BIFOLD":
            row.frameProfile = "CO"
            break;
        case "LH-POCKET":
        case "RH-POCKET":
        case "DBL POCKET":
            row.frameProfile = "PO"
            break;
        case "LHLH-COMM":
        case "RHRH-COMM":
        case "LHRH-COMM":
            row.frameProfile = "COMM"
            break;
        case "LHR/LHR":
        case "RHR/RHR":
            row.frameProfile = "DE"
            break;
    }
}

export const isWindow = (input: QuoteOpening | Opening) => input.openingType === openingTypeWindow;
export const openingTypeWindow = "Window";

export function PrepLocationCol<T extends (QuoteOpening | Opening)>(): Column<T> {
    return {
        name: "Prep Locations",
        width: 250,
        render: (data: T, col: any) => {
            if(data.openingType === openingTypeWindow) return null;

            if(!data.hardwareGroupPrepConfigIsCustom) return <div style={{fontStyle: "italic", textDecoration: "underline"}}>Standard</div>
            return <div style={{fontStyle: "italic", textDecoration: "underline"}}>Custom</div>
        },
        isChangedSinceBackup: (a: T) => {
            // @ts-ignore
            if(!a.backup) return false;

            // @ts-ignore
            if(!a.hardwareGroupPrepConfigIsCustom && !a.backup.hardwareGroupPrepConfigIsCustom) return false;

            const before = a.hardwareGroupPrepConfig || [];
            // @ts-ignore
            const after = a.backup.hardwareGroupPrepConfig || []

            const merged = mergeArrayByKey(before, after, v => v.name)
            return merged.some(v => !objEq(v.before, v.after))
        },
        editable: {
            type: "button",
            render: (props) => <PrepLocations value={props.row} onDone={props.onDone} onCancel={props.onCancel} />,
        }
    }
}

function objEq<T extends object>(a: T | undefined, b: T | undefined) {
    if(a === b) return true;
    if(!a || !b) return false;

    const keys = new Set([...Object.keys(a), ...Object.keys(b)]);

    for(const k of Array.from(keys)) {
        // @ts-ignore
        if(a[k] !== b[k]) return false;
    }

    return true;
}

function mergeArrayByKey<T>(before: T[], after: T[], key: (v: T) => string) {
    const beforeMap = new Map<string, T>();
    for(const v of before) {
        beforeMap.set(key(v), v);
    }

    const afterMap = new Map<string, T>();
    for(const v of after) {
        afterMap.set(key(v), v);
    }

    const keys = new Set([...Array.from(beforeMap.keys()), ...Array.from(afterMap.keys())]);
    const out: {key: string; before?: T, after?: T}[] = [];

    for(const k of Array.from(keys)) {
        const a = afterMap.get(k);
        const b = beforeMap.get(k);

        out.push({
            key: k,
            before: b,
            after: a,
        })
    }

    return out;
}

export function hasCustomPrep(input: PrepDetail[] | undefined) {
    if(!input) return false;

    const customizations = input.filter(v => !!v.value);
    return customizations.length > 0
}

export function AnchorCol<T extends (QuoteOpening | Opening)>(): Column<T> {
    return {
        name: "Anchor",
        width: 250,
        render: (data: T, col: any) => {
            if(!data.frameAnchorProductName) return "";

            return (
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div>
                        {data.frameAnchorProductName}
                    </div>
                    <AnchorQty data={data} />
                </div>
            );
        },
        editObj: (dst, src, value: string) => {
            if(value === "") {
                dst.frameAnchorProduct  = undefined
            }
        },
        editable: {
            type: "custom",
            copy: data => {
                return JSON.stringify([data.frameAnchorProduct, data.frameAnchorQtyOverride]);
            },
            paste: (data, value) => {
                if(value === "") {
                    data.frameAnchorProduct = undefined;
                    data.frameAnchorQtyOverride = null;
                    return;
                }

                const arr = JSON.parse(value);
                data.frameAnchorProduct = arr[0]
                data.frameAnchorQtyOverride = arr[1]
            },
            render: (props) =>
                <ProductCol
                   kind="frame-anchor"
                   width={props.width}
                   anchor={props.anchor}
                   onDone={(v: {product: number}[]) => props.onDone(Object.assign({}, props.row, {
                      frameAnchorProduct: v[0].product,
                   }))}
                   onCancel={props.onCancel}
                   initialValue={props.row.frameAnchorProductName || ""}
                />,
        },
        isChangedSinceBackup: (data: any) => {
            if(!data.backup) return false
            const backup = data.backup;

            return data.frameAnchorProduct !== backup.frameAnchorProduct || data.frameAnchorQtyOverride !== backup.frameAnchorQtyOverride;
        }
    }
}

export const locationTransitions = ["To", "From", "To/From"];
export const openingTypes = ["Single", "Pair", "Window"]

export function doorPreps<T extends {openingType: string}>(input: SelectInput<T>) {
    const data = input.props("openingType")

    if(data.openingType === "Pair") {
        return [
            {value: "RP", display: "RP: Vertical Rod"},
            {value: "161 & ASA/FB", display: "161 & ASA/FB: Lockset & ASA/FB"},
            {value: "161 & ES/FB", display: "161 & ES/FB: Lockset & ES/FB"},
            {value: "Mort & ASA/FB", display: "Mort & ASA/FB: Mortise Lock & ASA/FB"},
            {value: "Custom", display: "Custom: Custom Prep"},
        ]
    }

    return [
        {value: "161", display: "161: Lockset"},
        {value: "RP", display: "RP: Exit Device"},
        {value: "RP & Trim", display: "RP & Trim: Exit Device w/ Trim"},
        {value: "161 & DB", display: "161 & DB: Lockset & Deadbolt"},
        {value: "Mort", display: "Mort: Mortise Lockset"},
        {value: "Custom", display: "Custom: Custom Prep"},
    ]
}

export function lookupHollowMetalMaterial(value: string) {
    const list = [
        {display: "A-40: Standard Galvanized Finish", value: "A-40"},
        {display: "G-90: Galvanized Finish", value: "G-90"},
    ]

    return first(list, l => l.value === value);
}

export function frameMaterial<T extends {frameSeries: string}>(input: SelectInput<T>) {
    const data = input.props("frameSeries");

    if(data.frameSeries === aluminumFrame) {
        return []
    } else if(data.frameSeries === hollowMetalFrame) {
        return [
            lookupHollowMetalMaterial("A-40"),
            lookupHollowMetalMaterial("G-90"),
        ]
    } else if(data.frameSeries === "WD Frame") {
        return []
    }

    return [];
}

export function frameGauge<T extends {frameSeries: string}>(input: SelectInput<T>) {
    const data = input.props("frameSeries")

    if(data.frameSeries === hollowMetalFrame) {
        return [
            {display: "14", value: "14"},
            {display: "16", value: "16"},
            {display: "18", value: "18"},
            {display: "20", value: "20"},
        ]
    }

    return [];
}

export function frameProfile<T extends {frameSeries: string}>(input: SelectInput<T>) {
    return [
        {value: "STD", display: "STD: Standard"},
        {value: "EXP", display: "EXP: Expandable"},
        {value: "TB", display: "TB: Thermally Broken"},
        {value: "CO", display: "CO: Cased Open"},
        {value: "DE", display: "DE: Double Egress Frame"},
        {value: "FF", display: "FF: Flush Fit"},
        {value: "DW", display: "DW: Drywall (DBL Bend)"},
        {value: "SL", display: "SL: Shadow Line"},
        {value: "SR", display: "SR: Single Rabbet"},
        {value: "KF", display: "KF: Kerf"},
        {value: "HS", display: "HS: Hospital Stop"},
        {value: "PO", display: "PO: Pocket"},
        {value: "COM", display: "COM: Communicating"},
    ]
}

export function frameConstructionLookup(value: string) {
    const list = [
        {value: "WELD", display: "WELD: Welded"},
        {value: "KD", display: "KD: Knockdown"},
    ]

    return first(list, v => v.value === value);
}

export function frameConstruction<T extends {frameSeries: string}>(input: SelectInput<T>) {
    return [
        frameConstructionLookup("WELD"),
        frameConstructionLookup("KD"),
    ];
}

export function anchorLookup(value: string) {
    const list = [
        {value: "AWA", display: "AWA: Adjustable Wall Anchor"},
        {value: "WIRE", display: "WIRE: Masonry Wire Anchor"},
        {value: "S&T", display: "S&T: Strap & Tension"},
        {value: "P&S", display: "P&S: Plug & Strap"},
        {value: "EMA", display: "EMA: Existing Masonry Anchor"},
        {value: "BASE", display: "BASE: Base/Floor Anchor"},
        {value: "Z", display: "Z: Welded \"Z\" Anchor"},
        {value: "T", display: "T: Masonry \"T\" Anchor"},
    ]

    return first(list, v => v.value === value);
}

export const hollowMetalDoor = "HM Door"
export const aluminumDoor = "AL Door"
export const hollowMetalFrame = "HM Frame"
export const woodFrame = "WD Frame";
export const aluminumFrame = "AL Frame";

export function doorMaterial<T extends {doorSeries: string}>(input: SelectInput<T>) {
    const data = input.props("doorSeries");

    if(data.doorSeries === "AL Door") {
        return []
    } else if(data.doorSeries === hollowMetalDoor) {
        return [
            lookupHollowMetalMaterial("A-40"),
            lookupHollowMetalMaterial("G-90"),
        ]
    } else if(data.doorSeries === woodDoor) {
        return [
            {display: "Hardboard", value: "Hardboard"},
            {display: "Natural Birch", value: "Natural Birch"},
            {display: "White Birch", value: "White Birch"},
            {display: "White Oak", value: "White Oak"},
            {display: "Red Oak", value: "Red Oak"},
            {display: "Natural Maple", value: "Natural Maple"},
            {display: "White Maple", value: "White Maple"},
            {display: "White Ash", value: "White Ash"},
            {display: "Mahogany", value: "Mahogany"},
            {display: "Cherry", value: "Cherry"},
            {display: "Walnut", value: "Walnut"},
            {display: "PLAM", value: "PLAM"},
        ]
    }

    return [];
}

export function doorGauge<T extends {doorSeries: string}>(input: SelectInput<T>) {
    const data = input.props("doorSeries")

    if(data.doorSeries === hollowMetalDoor) {
        return [
            {display: "14", value: "14"},
            {display: "16", value: "16"},
            {display: "18", value: "18"},
            {display: "20", value: "20"},
        ]
    }

    return [];
}

export function handingLookup(value: string) {
    const list = [
        {value: "LHA", display: "LHA: Left Hand Active"},
        {value: "RHA", display: "RHA: Right Hand Active"},
        {value: "LHA/RHA", display: "LHA/RHA: Both Hands Active"},
        {value: "LHRA", display: "LHRA: Left Hand Reverse Active"},
        {value: "RHRA", display: "RHRA: Right Hand Reverse Active"},
        {value: "LHRA/RHRA", display: "LHRA/RHRA: Both Hands Reverse Active"},
        {value: "LHA-DA", display: "LHA-DA: Left Hand Active, Double Acting"},
        {value: "RHA-DA", display: "RHA-DA: Right Hand Active, Double Acting"},
        {value: "LHA/RHA-DA", display: "LHA/RHA-DA: Both Hands Active, Double Acting"},
        {value: "L-LHR", display: "L-LHR: Right Hand, Double Egress"},
        {value: "R-RHR", display: "R-RHR: Left Hand, Double Egress"},
        {value: "POCKET", display: "Pocket"},
        {value: "BYPASS", display: "Bypass"},
        {value: "BIFOLD", display: "Bifold"},
        {value: "LH", display: "LH: Left Hand"},
        {value: "RH", display: "RH: Right Hand"},
        {value: "LHR", display: "LHR: Left Hand Reverse"},
        {value: "RHR", display: "RHR: Right Hand Reverse"},
        {value: "LHR/LHR", display: "Right Hand, Double Egress"},
        {value: "RHR/RHR", display: "Left Hand, Double Egress"},
        {value: "LH-DA", display: "LH-DA: Left Hand, Double Acting"},
        {value: "RH-DA", display: "RH-DA: Right Hand, Double Acting"},
        {value: "CO", display: "CO: Cased Open"},
        {value: "LH-POCKET", display: "LH-POCKET: Left Hand , Pocket"},
        {value: "RH-POCKET", display: "RH-POCKET: Right Hand, Pocket"},
        {value: "LH-SLIDE", display: "LH-SLIDE: Left Hand, Sliding"},
        {value: "RH-SLIDE", display: "RH-SLIDE: Right Hand, Sliding"},
        {value: "LH-BIFOLD", display: "LH-BIFOLD: Left Hand, Bifold"},
        {value: "RH-BIFOLD", display: "RH-BIFOLD: Right hand, Bifold"},
        {value: "LHLH-COMM", display: "LH/LH-COMM: LH/LH, Communicating"},
        {value: "RHRH-COMM", display: "RH/RH-COMM: RH/RH, Communicating"},
        {value: "LHRH-COMM", display: "LH/RH-COMM: LH/RH, Communicating"},
        {value: "DBL POCKET", display: "DBL POCKET: Pocket Pair"},
        {value: "DBL BYPASS", display: "DBL BYPASS: Bypass Pair"},
        {value: "DBL BIFOLD", display: "DBL BIFOLD: Bifold Pair"},
        {value: "LH-BARN", display: "LH-BARN: Left Hand, Barn"},
        {value: "RH-BARN", display: "RH-BARN: Right Hand, Barn"},
    ]

    const val = first(list, v => v.value === value);
    if(!val) throw new Error("missing value for '" + value + "'")
    return val;
}

export function handingOptions<T extends {openingType: string}>(data: SelectInput<T>) {

    if(data.props("openingType").openingType === "Pair") {
        return [
            handingLookup("LHA"),
            handingLookup("RHA"),
            handingLookup("LHA/RHA"),
            handingLookup("LHRA"),
            handingLookup("RHRA"),
            handingLookup("LHRA/RHRA"),
            handingLookup("LHA-DA"),
            handingLookup("RHA-DA"),
            handingLookup("LHA/RHA-DA"),
            handingLookup("LHR/LHR"),
            handingLookup("RHR/RHR"),
            handingLookup("DBL POCKET"),
            handingLookup("DBL BYPASS"),
            handingLookup("DBL BIFOLD"),
        ]
    }

    return [
        handingLookup("LH"),
        handingLookup("RH"),
        handingLookup("LHR"),
        handingLookup("RHR"),
        handingLookup("LH-DA"),
        handingLookup("RH-DA"),
        handingLookup("CO"),
        handingLookup("LH-POCKET"),
        handingLookup("RH-POCKET"),
        handingLookup("LH-BARN"),
        handingLookup("RH-BARN"),
        handingLookup("LH-BIFOLD"),
        handingLookup("RH-BIFOLD"),
        handingLookup("LHLH-COMM"),
        handingLookup("RHRH-COMM"),
        handingLookup("LHRH-COMM"),

    ];
}

export const labels = ["20 Min", "45 Min", "90 Min"]
export const frameSeries = [aluminumFrame, hollowMetalFrame, woodFrame]
export const doorSeries = [aluminumDoor, hollowMetalDoor, woodDoor]

export function doorThicknesses(sys: UnitSystem | undefined){
    switch(sys) {
        case "metric":
            return ['35mm', '45mm']
        default:
            return ['1 3/8"', '1 3/4"']
    }
}

export function doorElevations<T>(data: T) {
    return [
        {value: "SLAB", display: "SLAB: Slab"},
        {value: "NL", display: "NL: Narrow Lite"},
        {value: "HG", display: "HG: Half Glass"},
        {value: "DG", display: "DG: Double Glass"},
        {value: "FG", display: "FG: Full Glass"},
        {value: "1 PANEL", display: "1 PANEL: 1 Panel Embossed"},
        {value: "2 PANEL", display: "2 PANEL: 2 Panel Embossed"},
        {value: "6 PANEL", display: "6 PANEL: 6 Panel Embossed"},
        {value: "LOUVER", display: "LOUVER: Louver"},
        {value: "DNL", display: "DNL: Double Narrow Lite"},
        {value: "DUTCH", display: "DUTCH: Dutch"},
    ]
}

export function doorSeamLookup(value: string) {
    const list = [
        {value: "LS", display: "LS: Lock Seam"},
        {value: "TW", display: "TW: Tack Weld"},
        {value: "T&F", display: "T&F: Tack & Fill"},
        {value: "FW", display: "FW: Full Weld"},
        {value: "MO", display: "MO: Manufacturers Option"},
        {value: "ME", display: "ME: Matching Edge"},
        {value: "VE", display: "VE: Veneer Edge"},
    ]

    return first(list, l => l.value === value);
}

export function doorFinish<T extends {doorSeries: string}>(input: SelectInput<T>) {
    const data = input.props("doorSeries")

    if (data.doorSeries === woodDoor) {
        return [
            {value: "Paint Grade", display: "Paint Grade"},
            {value: "Primed", display: "Primed"},
            {value: "Stain Grade", display: "Stain Grade"},
            {value: "Factory Painted", display: "Factory Painted"},
            {value: "Factory Clear", display: "Factory Clear"},
            {value: "Factory Stained", display: "Factory Stained"},
        ]
    }

    return [];
}

export function doorSeam<T extends {doorSeries: string}>(input: SelectInput<T>) {
    const data = input.props("doorSeries")

    if(data.doorSeries === hollowMetalDoor) {
        return [
            doorSeamLookup("LS"),
            doorSeamLookup("TW"),
            doorSeamLookup("T&F"),
            doorSeamLookup("FW"),
        ]
    } else if (data.doorSeries === woodDoor) {
        return [
            doorSeamLookup("MO"),
            doorSeamLookup("ME"),
            doorSeamLookup("VE"),
        ]
    }

    return [];
}

export function doorCoreLookup(value: string, doorSeries: string) {
    const list = [
        {value: "HC", display: "HC: Honeycomb", description: "Honeycomb"},
        {value: "PS", display: "PS: Polystyrene", description: "Polystyrene"},
        {value: "PU", display: "PU: Polyurethane", description: "Polyurethane"},
        {value: "TR", display: "TR: Temperature Rise", description: "Temperature Rise"},
        {value: "SS", display: "SS: Steel Stiffened", description: "Steel Stiffened"},
    ]

    if(doorSeries === hollowMetalDoor) {
        return first(list, v => v.value === value)
    }

    const list2 = [
        {value: "HC", display: "HC: Hollow", description: "Hollow"},
        {value: "PART", display: "PART: Particle", description: "Particle"},
        {value: "MIN", display: "MIN: Mineral", description: "Mineral"},
    ]

    if(doorSeries === woodDoor) {
        return first(list2, v => v.value === value)
    }

    return null;
}

export function doorCore<T extends {doorSeries: string}>(input: SelectInput<T>) {
    const data = input.props("doorSeries")

    if(data.doorSeries === hollowMetalDoor) {
        return [
            doorCoreLookup("HC", data.doorSeries),
            doorCoreLookup("PS", data.doorSeries),
            doorCoreLookup("PU", data.doorSeries),
            doorCoreLookup("TR", data.doorSeries),
            doorCoreLookup("SS", data.doorSeries),
        ]
    } else if (data.doorSeries === woodDoor) {
        return [
            doorCoreLookup("HC", data.doorSeries),
            doorCoreLookup("PART", data.doorSeries),
            doorCoreLookup("MIN", data.doorSeries),
        ]
    }

    return [];
}

const minJambDepth = 3 * ImperialDistance.unitsPerInch;
const maxJambDepth = 13.25 * ImperialDistance.unitsPerInch;
    // , 0.125
export function JambDepthCol<T extends TID>(name: string, key: NestedKeyOf<T>, width=100) {

    // jamb depth is ALWAYS imperial (because that's the way it is)
    return {
        name: name,
        render: (o: T) => lookupNestedProp(o, key),
        editable: {
            type: "string",
            parseValue: imperialInchParser,
            validate: (input: string) => {
                if (input === "") return;

                const val = ImperialDistance.parse(input);
                if (val.universalValue() < minJambDepth) throw new Error(`Jamb depth can't be less than ${ImperialDistance.fromUniversal(minJambDepth).toInchesString()}`);
                if (val.universalValue() > maxJambDepth) throw new Error(`Jamb depth can't be greater than ${ImperialDistance.fromUniversal(maxJambDepth).toInchesString()}`);
                if (val.sixtyFourthsOfInch % 2 !== 0) {
                    throw new Error(`Jamb depth must be a multiple of 1/8"`);
                }
            }
        },
        editKey: key,
        width: width,
        filter: keyMatch(key),
        sort: sortString(key),
    } as Column<T>;
}