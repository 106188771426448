import {DrawingInfo} from "../MakeSubmittal";
import {useMemo} from "react";
import {PageRenderProvider} from "../../../../../misc/pdf/parts/PageContext";
import {ContainerPage} from "../../../../../misc/pdf/parts/ContainerPage";
import {TitleTextBlock} from "../../../../../misc/pdf/parts/TitleTextBlock";

export function AbbreviationPage(props: {
    drawingInfo: DrawingInfo;
    onReady(): void;
}) {
    const list = useMemo(() => {
        return [
            <Section title="Handings" content={props.drawingInfo.handings.map(m => m.split(": "))} />,
            <Section title="HM Materials" content={props.drawingInfo.hmMaterials.map(m => m.split(": "))} />,
            <Section title="Door Cores" content={props.drawingInfo.cores.map(m => m.split(": "))} />,
            <Section title="Door Seams" content={props.drawingInfo.seams.map(m => m.split(": "))} />,
            <Section title="Construction" content={props.drawingInfo.construction.map(m => m.split(": "))} />,
        ]
    }, [props.drawingInfo]);

    return (
        <PageRenderProvider data={list} onReady={props.onReady}>
            {data => {
                return (
                    <ContainerPage>
                        <TitleTextBlock title="ABBREVIATIONS">
                            {data.rows}
                        </TitleTextBlock>
                    </ContainerPage>
                );
            }}
        </PageRenderProvider>
    )
}

function Section(props: {
    title: string;
    content: string[][]
}) {

    if(props.content.length === 0) return null;

    return (
        <div>
            <div style={{fontWeight: "bold", fontSize: "1.4rem", textDecoration: "underline"}}>{props.title}</div>
            <div style={{paddingLeft: 20}}>
                <table><tbody>
                {props.content.map(c => <tr>
                    <td>{c[0]} - </td>
                    <td>{c[1]}</td>
                    </tr>
                )}
                </tbody>
                </table>
            </div>
        </div>
    )
}
