import {hasPermission, useUser} from "../../../../misc/Permission";
import {api} from "../../../../api/API";
import {
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import React, {useContext} from "react";
import {useAlternative} from "../../quote/alternative/Alternative";
import {ManagerLockContext} from "../../shopdrawing/ProjectActions";
import {useProjectId} from "../../shopdrawing/ProjectName";
import {usePriceCellCustomize} from "../../shopdrawing/pricing/PriceCellCustomize";
import {priceTableName} from "../../shopdrawing/pricing/Pricing";
import {priceDetailEmitter} from "../../shopdrawing/pricing/UnPricedItems";
import {getAll} from "../../shopdrawing/openings/Openings";
import {commonGroupBy, commonPricingColumns} from "../../shopdrawing/pricing/Frames";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {DoorSectionProvider} from "../../shopdrawing/pricing/DoorSectionProvider";


export function QuoteDoors(props: {
    type: "hm" | "wd" | "other"
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const alternative = useAlternative();
    const customize = usePriceCellCustomize();

    if(!u) return null;
    const isWood = props.type === "wd";

    return (
        <DoorSectionProvider value={props.type}>
            <Table<QuotePriceLine>
                key={props.type}
                name={priceTableName(project, "door", props.type)}
                globalPrefsName={priceTableName(0, "door", props.type)}
                locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
                cellCustomize={customize}
                fetch={async (ctx) => {
                    const rs = await getAll(ctx, offset => api.quotePricing.list({project: project, alternative, offset}))
                    priceDetailEmitter.emit(rs);
                    return rs;
                }}
                columns={[
                    ViewOnly({
                        name: "Series",
                        render: (dt: QuotePriceLine) => {
                            if(dt.productType === "door-prep") return `${dt.productName}: ${dt.productCode}`;
                            return dt.door.series;
                        },
                        width: 150,
                    }),
                    ViewOnly(StringCol("Material", "door.material")),
                    isWood ? ViewOnly(StringCol("Finish", "door.finish")) : null,
                    ViewOnly(StringCol("Gauge", "door.gauge")),
                    ViewOnly(StringCol("Elevation", "door.elevation")),
                    ViewOnly(StringCol("Core", "door.core")),
                    ViewOnly(StringCol("Seam", "door.seam")),
                    ViewOnly(StringCol("Handing", "door.handing")),
                    ViewOnly(StringCol("Label", "door.label")),
                    ViewOnly(StringCol("Height", "door.height")),
                    ViewOnly(StringCol("Width", "door.width")),
                    ViewOnly(StringCol("Thickness", "door.thickness")),
                    ...commonPricingColumns(u, true),
                ]}
                fetchDeps={[project, alternative]}
                columnDeps={[u, props.type]}
                groupBy={Object.assign({}, commonGroupBy, {
                    groupFilter: (groupName: string) => {
                        return groupName === "door-" + props.type || groupName === "project" || groupName === "door-totals" || groupName.startsWith("door-prep/" + props.type)
                    },
                })}
                onChange={(input) => api.quotePricing.upsert(input)}
            />
        </DoorSectionProvider>
    )
}
