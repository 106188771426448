import {ProductPreview, ReceiveInput} from "../../../api/Logistics";
import {deepOrange, green, red} from "@mui/material/colors";
import {css} from "@emotion/css";

export function DriverLogDetail(props: {
    log: ReceiveInput;
    content: ProductPreview;
}) {
    const matches = (props.log.items || []).filter(i => i.product === props.content.product);
    // @ts-ignore
    const extraMatches = (props.log.extras || []).filter(i => i.product.product === props.content.product);

    let got = 0;
    let damaged = 0;

    matches.map(i => {
        got += i.received

        if(i.damaged) {
            damaged += i.damaged;
        }

        return null;
    })

    extraMatches.map(i => {
        if(!i.status) return null;
        got += i.status.received

        if(i.status.damaged) {
            damaged += i.status.damaged;
        }

        return null;
    });

    return <DriverLogDetailRow got={got} damaged={damaged} expected={props.content.qty} />
}

export function DriverLogDetailRow(props: {
    got: number;
    damaged: number;
    expected: number;
}) {
    return (
        <div className={containerClass}>
            {props.damaged > 0 && <div style={{color: red["800"]}}>-{props.damaged}</div>}
            <div style={{color: props.got >= props.expected ? green["800"] : deepOrange["600"]}}>
                {Math.min(props.expected, props.got)}
            </div>
            {props.got > props.expected && <div style={{color: deepOrange["600"]}}>
                +{props.got - props.expected}
            </div>}
        </div>
    )
}

const containerClass = css({
    display: "flex",
    justifyContent: "flex-end",

    "& > div": {
        marginLeft: 8,
    }
})