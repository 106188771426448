import {Popper, Paper, Grid, IconButton, Select, MenuItem} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../../api/API";
import {useProjectId} from "./ProjectName";
import {Loader} from "../../../misc/Loader";
import {Checklist, ChecklistItem} from "../../../api/Projects";
import {useSnackbar} from "../../../misc/Snackbar";
import {deepOrange, grey} from "@mui/material/colors";
import WarningIcon from '@mui/icons-material/Warning';
import {css} from "@emotion/css";

const frameGauges = ["12ga", "14ga", "16ga", "18ga"];
const doorGauges = ["14ga", "16ga", "18ga", "20ga"];
const frameFinishes = ["A-40", "G-90"];
const doorFinishes = frameFinishes;
const frameConstructions = ["Welded", "Knockdown", "Expandable KD"];
const cores = ["Honeycomb", "Polystyrene", "Polyurethane", "Temperature Rise", "Steel Stiffened"];
const seams = ["Lock Seam", "Tack Weld", "Tack & Fill", "Full Weld"];

type PropGroup = {
    key: string;
    display: string;
    items: PropItem[]
}

type PropItem = {
    key: string;
    display: string;
    options: string[];
}

const properties: PropGroup[] = [{
   key: "hollow-metal",
   display: "Hollow Metal",
   items: [{
       key: "exterior-frame-construction",
       display: "Exterior Frame Construction",
       options: frameConstructions,
   },{
       key: "exterior-frame-gauge",
       display: "Exterior Frame Gauge",
       options: frameGauges,
   },{
       key: "exterior-frame-finish",
       display: "Exterior Frame Finish",
       options: frameFinishes,
   },{
       key: "interior-frame-construction",
       display: "Interior Frame Construction",
       options: frameConstructions,
   },{
       key: "interior-frame-gauge",
       display: "Interior Frame Gauge",
       options: frameGauges,
   },{
       key: "interior-frame-finish",
       display: "Interior Frame Finish",
       options: frameFinishes,
   }, {
       key: "exterior-door-gauge",
       display: "Exterior Door Gauge",
       options: doorGauges,
   },{
       key: "exterior-door-finish",
       display: "Exterior Door Finish",
       options: doorFinishes,
   },{
       key: "exterior-door-core",
       display: "Exterior Door Core",
       options: cores,
   },{
       key: "exterior-door-seam",
       display: "Exterior Door Seam",
       options: seams,
   }, {
       key: "interior-door-gauge",
       display: "Interior Door Gauge",
       options: doorGauges,
   },{
       key: "interior-door-finish",
       display: "Interior Door Finish",
       options: doorFinishes,
   },{
       key: "interior-door-core",
       display: "Interior Door Core",
       options: cores,
   },{
       key: "interior-door-seam",
       display: "Interior Door Seam",
       options: seams,
   }]
}, {
    key: "wood-door",
    display: "Wood Door",
    items: [{
        key: "core",
        display: "Wood Door Core",
        options: ["Hollow", "Particle", "Mineral"],
    }, {
        key: "veneer-type",
        display: "Wood Door Veneer Type",
        options: ["Hardboard", "Natural Birch", "White Birch", "White Oak", "Red Oak", "Natural Maple", "White Maple", "White Ash"],
    }, {
        key: "seam",
        display: "Wood Door Seam",
        options: ["Manufacturer's Option", "Matching Edge", "Veneer Edge"],
    }, {
        key: "finish",
        display: "Wood Door Finish",
        options: ["Paint Grade", "Stain Grade", "Factory Finish (Standard Stain)", "Factory Finish (Custom Stain)", "Factory Finish (Paint)", "PLAM (Specified", "PLAM (Selection by Consultant)"],
    }],
}];

function makeSessionVar(key: string) {
    return {
        get: () => {
            return localStorage.getItem(key);
        },
        set: (value: string) => {
            return localStorage.setItem(key, value);
        }
    }

}

const session = makeSessionVar("show-project-properties")

export function ProjectProperties() {
    const [ref, setRef] = useState<HTMLDivElement|null>(null);
    const [collapsed, setCollapsed] = useState(session.get() === "true");
    const snack = useSnackbar();

    useEffect(() => {
        session.set(collapsed ? "true" : "false");
    }, [collapsed])

    const project = useProjectId();
    const checklist = useAsync2((input) => api.projects.getChecklist(input), {project: project}, [project])

    const [value, setValue] = useState<Checklist>({});
    const valueRef = useRef(value);

    useEffect(() => {
        if(!checklist.result) return;
        setValue(checklist.result)
        valueRef.current = checklist.result || {};
    }, [checklist.result]);

    let countDone = 0;
    let totalCount = 0;
    properties.map(group => group.items.map(item => {

        totalCount++;
        const row = value[group.key+"."+item.key]?.value;
        if(!!row && row !== "-") {
            countDone++;
        }

        return null;
    }))

    return (
        <>
            <div ref={setRef} style={{position: "fixed", bottom: 10, right: 10}} />
            <Popper anchorEl={ref} placement="top-end" style={{zIndex: 12}} open={true}>
                <Paper elevation={1} style={{border: "1px solid " + grey["200"], width: collapsed ? undefined : 600, maxHeight: 500, display: "flex", flexDirection: "column"}}>
                    <Loader {...checklist}>
                        {data => (<>
                            <div style={{padding: 8, paddingLeft: 16, paddingBottom: 4, borderBottom: "1px solid " + grey["200"]}}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <div style={{fontWeight: "bold"}}>Project Properties</div>
                                    </Grid>
                                    {countDone < totalCount && <Grid item style={{color: deepOrange["700"]}}>
                                        <div style={{fontSize: "1.2rem", height: "1em", overflow: "hidden"}}>
                                            <WarningIcon fontSize='inherit' />
                                        </div>
                                    </Grid>}
                                    <Grid item style={{fontSize: "0.8rem"}}>
                                        {countDone}/{totalCount}
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={() => setCollapsed(!collapsed)}>
                                            {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                            {!collapsed && <div style={{fontSize: "0.8rem", overflow: "auto", flex: 1}}>
                                {properties.map(group => (<div key={group.key}>
                                        <div style={{fontWeight: "bold", paddingLeft: 16, paddingTop: 16}}>{group.display}</div>
                                        <div>
                                            {group.items.map(item => {
                                                const key = group.key + "." + item.key;
                                                return (
                                                    <GroupItem value={value[key]} label={item.display} options={item.options} onChange={async value => {
                                                        valueRef.current = Object.assign({}, valueRef.current, {
                                                            [key]: value
                                                        });

                                                        setValue(valueRef.current);

                                                        try {
                                                            await api.projects.saveChecklist({
                                                                project: project,
                                                                checklist: valueRef.current,
                                                            })
                                                        } catch (e: any) {
                                                            snack.error(e.toString())
                                                        }
                                                    }} />
                                                );
                                            })}
                                        </div>
                                    </div>))}
                            </div>}
                        </>)}
                    </Loader>
                </Paper>
            </Popper>
        </>
    )
}

function GroupItem(props: {
    value?: ChecklistItem;
    label: string;
    options: string[];
    onChange(value: ChecklistItem): void;
}) {
    const [comment, setComment] = useState(props.value?.comment || "");

    const parentComment = props.value?.comment || "";
    useEffect(() => {
        setComment(parentComment);
    }, [parentComment]);

    const value = props.value?.value || "-"

    return (
        <div style={{paddingLeft: 16, paddingRight: 4}}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    {props.label}
                </Grid>
                <Grid item xs={3}>
                    <Select<string> className={selectStyle} fullWidth size="small"
                            variant="standard"
                            style={{fontSize: "0.75rem"}}
                            disableUnderline
                            value={value as any}
                            onChange={(e) => {
                                props.onChange(Object.assign({}, props.value, {
                                    value: e.target.value
                                }))
                            }}
                    >
                        <MenuItem value="-"><i>Choose a value</i></MenuItem>
                        {props.options.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item xs={5}>
                    <input type="text"
                           placeholder="Add a comment"
                           className={textStyle}
                           style={{width: "100%"}}
                           value={comment}
                           onChange={e => setComment(e.target.value)}
                           onBlur={e => {
                               props.onChange(Object.assign({}, props.value, {
                                   comment: e.target.value
                               }))
                           }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

const textStyle = css({
    border: "none",
    width: "100%",
    fontSize: "0.75rem",

    "&::placeholder": {
        fontStyle: "italic",
    }
})

const selectStyle = css({
    "& div": {
        paddingBottom: 0,
    }
})