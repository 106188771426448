import {MetricParser} from "./MetricParser";

export class MetricDistance {
    mm: number;

    // // see dim/main.go
    static unitsPerMM = 160;

    constructor(mm: number) {
        this.mm = Math.round(mm);
    }

    toString(): string {
        return this.mm.toString() + " mm";
    }

    static parse(value: string): MetricDistance {
        const parsed = MetricParser.parse(value);
        return new MetricDistance(parsed.mm);
    }
}