import {useGetAll} from "../../../logistics/routes/useGetAll";
import {api} from "../../../../api/API";
import React, {useEffect, useMemo, useState} from "react";
import {Category} from "../../../../api/Products";
import {Loader} from "../../../../misc/Loader";
import {List, ListItemButton, ListItemText, useTheme} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {EventEmitter, first} from "nate-react-api-helpers";
import {CategoryKind} from "./CreateProduct";

const blankArray: Category[] = [];

type CategoryNode = Category & {
    children: CategoryNode[],
    parent?: CategoryNode
}

function walk(nd: {children: CategoryNode[]}, cb: (input: {children: CategoryNode[]}) => void) {
    cb(nd);

    nd.children.forEach(c => {
        walk(c, cb);
    });
}

export const categoryEmitter = new EventEmitter<Category[]>();

export function CategoryTree(props: {
    value: number | null;
    mode: CategoryKind;
    onChange(value: number | null, info?: {level: number; isAutoQtyHinge: boolean}): void;
}) {
    const mode = props.mode;
    const categories = useGetAll((input) => api.products.categories(input), {
        kind: mode || undefined as any
    }, [mode]);
    const result = categories.result || blankArray;
    const [selected, setSelected] = useState<CategoryNode>();

    useEffect(() => {
        if(result.length === 0 && categoryEmitter.lastValue) return;
        categoryEmitter.emit(result);
    }, [result])

    const tree = useMemo(() => {

        const map = result
            .filter(r => mode === null || r.kind === mode)
            .reduce((acc, r) => {
                acc[r.id] = Object.assign({}, r, { children: [] });
                return acc;
            }, {} as {[id: number]: CategoryNode});

        const root = {
            children: [] as CategoryNode[],
        };

        for(let i in map) {
            const el = map[i];
            if(!el.parentCategory) {
                root.children.push(el);
                continue;
            }

            map[el.parentCategory].children.push(el);
            el.parent = map[el.parentCategory];
        }

        walk(root, nd => {
            nd.children.sort((a, b) => {
                if(a.sortOrder === null) {
                    if(b.sortOrder === null) return 0;
                    return 1;
                }

                if(b.sortOrder === null) return -1;

                return a.sortOrder - b.sortOrder;
            });
        })

        return root;
    }, [result, mode]);

    const onChange = props.onChange;
    const propValue = props.value;

    useEffect(() => {
        if(result.length === 0) return;
        if(!propValue) return;

        let nodes = [tree];
        while(nodes.length > 0) {

            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];

                const find = first(node.children, c => c.id === propValue);
                if (find) {
                    setSelected(find)
                    return;
                }
            }

            let nextNodes: any = [];
            nodes.map(n => nextNodes.push(...n.children));
            nodes = nextNodes;
        }

        // clear parent result because it doesn't exist
        onChange(null);
    }, [propValue, tree, result, onChange])

    useEffect(() => {
        if(!selected) {
            if(propValue) return;

            onChange(null);
            return;
        }

        let cursor: any = selected;
        let level = 0;
        let list: (typeof selected)[] = [];

        while(cursor) {
            list.push(cursor);
            cursor = cursor.parent;

            level++
        }

        onChange(selected.id,{
            level: level,
            isAutoQtyHinge: list.filter(l => l?.name.toLowerCase().indexOf("hinge") !== -1 && l?.name.toLowerCase().indexOf("continuous") === -1).length > 0,
        })
    }, [selected, onChange, propValue]);

    const theme = useTheme();

    return <Loader {...categories}>
        {() => {
            let list = selected ? selected.children : tree.children;
            let parents: CategoryNode[] = []

            let cursor = selected;
            if(cursor && cursor.children.length === 0) {
                cursor = cursor.parent;
            }

            while(cursor) {
                parents.push(cursor);
                cursor = cursor.parent
            }

            parents = parents.reverse()

            return (
                <List dense className={listClass}>
                    <ListItemButton className={selectedCategoryClass} onClick={() => setSelected(undefined)}>
                        <ListItemText primary="Category" />
                        <ExpandMoreIcon />
                    </ListItemButton>
                    {parents.map((c, index) =>
                        <ListItemButton className={selectedCategoryClass} onClick={() => {
                            if(c !== selected) {
                                setSelected(c)
                                return;
                            }

                            if(index > 0) {
                                setSelected(parents[index-1]);
                                return;
                            }

                            setSelected(undefined);
                        }}>
                            <ListItemText primary={c.name} style={{paddingRight: 16}} />
                            <ExpandMoreIcon />
                        </ListItemButton>)}
                    {selected && list.length === 0 && <div style={{
                        border: "2px solid " + theme.palette.primary.light,
                    }}>
                        <ListItemButton>
                            <ListItemText primary={selected.name} />
                        </ListItemButton>
                    </div>}
                    {list.map(c =>
                        <ListItemButton onClick={() => setSelected(c)}>
                            <ListItemText primary={c.name} />
                        </ListItemButton>
                    )}
                </List>
            )
        }}
    </Loader>
}

const selectedCategoryClass = css({
    "&.MuiListItemButton-root": {
        backgroundColor: grey["200"],
        border: "1px solid " + grey["300"],
    }
})

const listClass = css({
    "& .MuiListItemText-root": {
        whiteSpace: "nowrap",
    }
})