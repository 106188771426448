import {createContext, forwardRef, useMemo} from "react";
import {useUser} from "../../../../misc/Permission";
import {Opening} from "../../../../api/Openings";
import {DrawingInfo, ExportColumn} from "./MakeSubmittal";
import {PageRenderProvider} from "../../../../misc/pdf/parts/PageContext";
import {PDF} from "../../../../misc/pdf/parts/PDF";
import {AbbreviationPage} from "./pdf/AbbreviationPage";
import {OpeningsPage} from "./pdf/OpeningsPage";
import {Project} from "../../../../api/Projects";
import {Company} from "../../../../api/Companies";
import {CoverPage} from "../../../../misc/pdf/parts/CoverPage";
import {ContainerPage} from "../../../../misc/pdf/parts/ContainerPage";
import {useReadyCheck} from "../../../../misc/pdf/UseReadyCheck";
import {TitleTextBlock} from "../../../../misc/pdf/parts/TitleTextBlock";
import {TodoPage} from "../../../../misc/pdf/parts/TodoPage";
import {ImageTilePage} from "./ImageTilePage";
import {QuoteOpening} from "../../../../api/QuoteOpenings";

export const OpeningsPDF = forwardRef(function(props: {
    project: Project;
    client?: Company;
    data: (Opening|QuoteOpening)[]
    columns: ExportColumn[]
    preamble: string;
    drawingInfo: DrawingInfo;
    lastSubmittal: string|null;
    screenElevations: string[];
    doorElevations: string[];
    onReady(): void;
}, ref: any) {
    const usr = useUser();
    const ready = useReadyCheck(props.onReady);
    ready.require(!!usr);

    const preamble = useMemo(() => props.preamble.split("\n"), [props.preamble])

    return (
        <div ref={ref}>
            <PDF>
                <PDFProjectContext.Provider value={props.project}>
                    <CoverPage
                        client={props.client}
                        title="DOOR & FRAME SHOP DRAWINGS"
                        lastSubmittal={props.lastSubmittal}
                    />
                    <PageRenderProvider data={preamble} onReady={ready.section()}>
                        {info =>
                            <ContainerPage>
                                <TitleTextBlock title="PREAMBLE">
                                    {info.rows.map((d, index) => <div key={index.toString()}>{d}</div>)}
                                </TitleTextBlock>
                            </ContainerPage>}
                    </PageRenderProvider>
                    <AbbreviationPage drawingInfo={props.drawingInfo} onReady={ready.section()} />
                    <OpeningsPage columns={props.columns} data={props.data} onReady={ready.section()} />
                    <TodoPage title="FRAME CROSS SECTIONS" />
                    <TodoPage title="ANCHOR ELEVATIONS" />
                    <ImageTilePage title={"SCREEN ELEVATIONS"} images={props.screenElevations} />
                    <ImageTilePage title={"DOOR ELEVATIONS"} images={props.doorElevations} />
                </PDFProjectContext.Provider>
            </PDF>
        </div>
    )
});

export const PDFProjectContext = createContext<Project>({} as Project);