import {ReceiveItem, TItem} from "./ReceiveItem";
import {AsyncResult2, first, useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {ReceiveInput} from "../../../api/Logistics";
import {useState} from "react";
import {Extra, Status} from "./ReceivePO";
import {api} from "../../../api/API";
import {MobileContainer} from "../../../misc/Container";
import {Loader} from "../../../misc/Loader";
import {EditReceivingItem} from "./EditReceivingItem";
import {cardStyle} from "../driver/RouteStops";
import {Button, styled} from "@mui/material";
import {CreatePOItem} from "./CreatePOItem";
import {useSyncedRef} from "../../../misc/SyncedRef";

type ContextInput = {
    product: number;
    qty: number;
}

export function DeliverOrReceive<T extends TItem>(props: {
    delivery?: number;
    purchaseOrder?: number;

    list?: AsyncResult2<T[]|null>;
    routeItem?: number;

    atWarehouse?: boolean

    title?: string;

    backURL: string;
    submitTitle: string;
    onSubmit: (input: ReceiveInput) => Promise<any>
}) {
    const [status, setStatus] = useState<Status[]>([]);
    const [contextMenu, setContextMenu] = useState<ContextInput|null>(null);
    const [extras, setExtras] = useState<Extra[]>([]);
    const [showCreate, setShowCreate] = useState(false);

    const item = useAsync2(input => {
        if(!input.id) return Promise.reject(new Error("no id"));
        return api.logistics.getRouteItem(input as any)
    }, {id: props.routeItem}, [props.routeItem])

    const name = useAsync2(async input => {
        if(input.purchaseOrder) {
            const po = await api.logistics.getPO({id: input.purchaseOrder})
            return {
                name: "PO #" + po.poNumber,
                supplier: po.supplier,
            }
        }

        if(input.delivery) {
            const del = await api.logistics.getDelivery({id: input.delivery})
            return {
                name: del.projectName,
                supplier: null,
            }
        }

        throw new Error("invalid")
    }, {
        purchaseOrder: props.purchaseOrder,
        delivery: props.delivery,
    }, [props.purchaseOrder, props.delivery])

    const list = (props.list?.asList || item.result?.contents || []) as T[];
    const actionProducts = props.atWarehouse ? list : list.filter(v => !v.toWarehouse);
    const notDeliverProducts = list.filter(v => actionProducts.indexOf(v) === -1);

    const submitRef = useSyncedRef(props.onSubmit);
    const submit = useAsyncAction( (input) => {
        return submitRef.current(input);
    }, []);

    return (
        <MobileContainer topCenter={props.title || name.result?.name || "Loading..."} back={props.backURL}>
            {props.list && <Loader {...props.list} />}
            {props.routeItem && <Loader {...item} />}
            {contextMenu && <EditReceivingItem status={first(status, v => v.product === contextMenu.product) || {
                status: "ok",
                product: contextMenu.product || -1,
                received: contextMenu.qty,
                damaged: undefined,
            }} onChange={v => {
                setStatus(old => [...old.filter(s => s.product !== v.product), v])
                setContextMenu(null);
            }} onCancel={() => setContextMenu(null)} />}

            <div style={{flex: 1}}>
                <div className={cardStyle} style={{padding: 0}}>
                <div style={{padding: 4, fontSize: "0.9rem"}}>Long press items for more options</div>
                    <table style={{width: "100%", borderCollapse: "collapse"}}><tbody>
                    {actionProducts.map(v => {
                        let value = first(status, s => s.product === v.product);

                        return (
                            <ReceiveItem pickup={!!item.result?.pickupPo} item={v} value={value} onEdit={setContextMenu as any} onStatusChange={updatedStatus => {
                                const updated = Object.assign(updatedStatus, {product: v.product}) as Status;

                                if(value) {
                                    setStatus(old =>
                                        old.map(o => o.product === v.product ?
                                            updated
                                            : o)
                                    );
                                } else {
                                    setStatus(old => [...old, updated])
                                }

                            }} />
                        );
                    })}
                    {extras.map(e => {
                        return (
                            <ReceiveItem item={e.product} value={e.status} onEdit={() => {}} onStatusChange={updatedStatus => {
                                if(updatedStatus.received === 0) {
                                    setExtras(old => old.filter(o => o !== e));
                                    return;
                                }

                                e.status = updatedStatus;
                                setExtras(old => old.slice(0));
                            }} />
                        );
                    })}
                    </tbody></table>
                    {notDeliverProducts.length > 0 &&
                        <>
                            <BlueSpace style={{height: 16}} />
                            <TitleBlue style={{padding: 8, textAlign: "center"}}>
                                <div style={{fontSize: "1.2rem", fontWeight: 800}}>Don't deliver to site</div>
                                <div style={{marginTop: 0, lineHeight: "1em", fontSize: "0.8rem"}}>Part of PO, but need to go back to warehouse</div>
                            </TitleBlue>
                            <table style={{width: "100%", borderCollapse: "collapse"}}><tbody>
                            {notDeliverProducts.map((d, index) => <ReceiveItem key={index} item={d} />)}
                            </tbody></table>
                        </>
                    }
                </div>
            </div>

            <div className={cardStyle} style={{padding: 0}}>
                <div style={{padding: 16}}>
                    <Button color="secondary" variant="contained" fullWidth onClick={() => setShowCreate(true)}>
                        Add Item
                    </Button>
                    <Loader {...submit} />
                    <div style={{height: 16}} />
                    <div className={cardStyle} style={{padding: 0}}>
                        <Button color="secondary" variant="contained"
                                fullWidth
                                disabled={status.length !== actionProducts.length}
                                onClick={() => {
                            const statuses = status;
                            const toAdd = (props.list?.asList || []).filter(l => !first(status, s => s.product === l.product));
                            statuses.push(...toAdd.map(a => ({
                                status: "back-order" as any,
                                product: a.product,
                                received: 0,
                            })))

                            submit.callback({
                                delivery: props.delivery || 0,
                                purchaseOrder: props.purchaseOrder || 0,
                                items: statuses,
                                extras: extras,
                            });
                        }}>
                            {props.submitTitle}
                        </Button>
                    </div>
                </div>
            </div>

            {showCreate && <CreatePOItem manufacturer={name.result?.supplier || null} onCancel={() => setShowCreate(false)} onDone={(item, qty) => {
                setShowCreate(false);

                item.qty = qty;
                setExtras(old => [...old, {
                    status: {
                        status: "ok",
                        received: qty,
                        product: 0,
                    },
                    product: item,
                }])
            }} />}
        </MobileContainer>
    )
}

const TitleBlue = styled("div")(props => ({
    backgroundColor: props.theme.palette.primary.main,
    color: "white",
}))

const BlueSpace = styled("div")(props => ({
    backgroundColor: props.theme.palette.primary.light,
}))