import {PriceLine} from "../../../../api/Pricing";
import {hasPermission, useUser} from "../../../../misc/Permission";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {
    AdjustCol, CentsCol,
    Column, Group,
    GroupByProp, lookupNestedProp,
    numberMatch, sortNumeric, sortStringInner,
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {formatCents} from "nate-react-api-helpers";
import {BeforeGroup, markupColumnName} from "./BeforeGroup";
import {AfterGroup, costEachColumnName, qtyColumnName} from "./AfterGroup";
import { isNullOrUndefined, MarkupEditCell, parseMarkup, priceTableName, profit} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {User} from "../../../../api/Users";
import {hollowMetalDoor, woodDoor} from "../../quote/openings/QuoteOpenings";
import {priceDetailEmitter} from "./UnPricedItems";
import {CostFocuser} from "./CostFocuser";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {ExtendedPriceEditor} from "./ExtendedPriceEditor";
import {deepOrange, green, red} from "@mui/material/colors";

export type ProposingTag = "replace" | "new"| "remove" | "change"

export type PriceLine2 = PriceLine & ({
    kind: "current";
    proposingTag?: ProposingTag;
    previous: PriceLine;
    removal?: PriceLine;
    initial: PriceLine; // raw price line before display modifications
}  | {
    kind : "previous";
    proposingTag?: ProposingTag;
    current: PriceLine;
});


export function Frames() {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();

    const proposal = useProposingKit()
    const previewChangeset = useContext(ChangePreviewContext);

    if(!u) return null;

    return (
        <Table<PriceLine2>
            name={priceTableName(project, "frame")}
            globalPrefsName={priceTableName(0, "frame")}
            cellCustomize={proposal.cellCustomize}
            locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
            fetch={async (ctx) => {
                const pRaw = await getAll(ctx, offset =>
                    api.pricing.list({
                        project: project,
                        offset,
                        previewChangeset: previewChangeset.enabled ? previewChangeset.sessionId : undefined,
                    })
                )

                const prices = proposal.transformRows(pRaw)

                prices.sort((a, b) => {
                    if(a.productType === b.productType) return 0;
                    if(a.productType === "frame-anchor") return 1;
                    if(b.productType === "frame-anchor") return -1;
                    return 0;
                });

                priceDetailEmitter.emit(prices);

                return prices;
            }}
            columns={[
                {
                    name: "",
                    render: o => proposal.rowAction(o, "frame", ""),
                    width: 150,
                },
                proposal.proposing && ViewOnly(AdjustCol(StringCol("Opening", "openingName", 150), {
                    render: (d: PriceLine2) => {
                        return proposal.renderItemName(d, "openingName")
                    }
                })),
                ViewOnly({
                    name: "Series",
                    render: (dt: PriceLine2) => {
                        if(dt.productType === "frame-anchor") return `Anchor: ${dt.productCode}`;
                        if(dt.productType === "frame-prep") return `${dt.productName}: ${dt.productCode}`;
                        return dt.frame.series
                    },
                    width: 200,
                }),
                ViewOnly(StringCol("Material", "frame.material")),
                ViewOnly(StringCol("Gauge", "frame.gauge")),
                ViewOnly(StringCol("Screen Elev", "frame.screenElev")),
                ViewOnly(StringCol("Profile", "frame.profile")),
                ViewOnly(StringCol("Jamb Depth", "frame.jambDepth")),
                ViewOnly(StringCol("Construction", "frame.construction")),
                ViewOnly(StringCol("Handing", "frame.handing")),
                ViewOnly(StringCol("Label", "frame.label")),
                ViewOnly(StringCol("Type", "frame.type")),
                ViewOnly(StringCol("Height", "frame.height")),
                ViewOnly(StringCol("Width", "frame.width")),
                ...proposal.modifyColumns(commonPricingColumns(u, false, proposal.proposing)),
            ]}
            fetchDeps={[project, proposal.proposing, proposal.expanded, previewChangeset.enabled, previewChangeset.sessionId]}
            columnDeps={[u, proposal.proposing]}
            groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy : commonGroupBy, {
                groupFilter: (groupName: string) => {
                    return groupName === "frame" || groupName.startsWith("frame/") || groupName === "project" ||
                        groupName === "frame-anchor" || groupName === "frame-totals" ||
                        groupName.startsWith("frame-anchor/") || groupName.startsWith("frame-prep")
                }
            })}
            onChange={(input) => api.pricing.upsert(proposal.processUpdate(input))}
        />
    )
}

const prefixSort = byPrefix([
    "frame", "frame-prep", "frame-totals", "door", "door-prep", "door-totals", "hardware-before", "hardware", "hardware-after", "div-10", "project",
]);

export const AdditionSuffix = "/addition"
export const ChangeSuffix = "/change"
export const RemovalSuffix = "/removal"

function splitGroup(base: string) {
    return [base + AdditionSuffix, base + ChangeSuffix, base + RemovalSuffix];
}

function categorizeChange(base: string, row: PriceLine2) {
    switch(row.proposingTag) {
        case "new":
            return base + AdditionSuffix;
        case "replace":
        case "change":
            return base + ChangeSuffix;
        case "remove":
            return base + RemovalSuffix;
    }

    return base;
}

function numericCategory(value: string) {
    if(value.endsWith(AdditionSuffix)) return 1;
    if(value.endsWith(ChangeSuffix)) return 2;
    if(value.endsWith(RemovalSuffix)) return 3;
    return 4;
}

function hasChangeSuffix(value: string) {
    if(value.endsWith(AdditionSuffix)) return true;
    if(value.endsWith(ChangeSuffix)) return true;
    if(value.endsWith(RemovalSuffix)) return true;
    return false;
}

function removeSuffix(a: string) {
    return a.replace(AdditionSuffix, "").replace(ChangeSuffix, "").replace(RemovalSuffix, "");
}

function categorySort(a: string, b: string) {

    if(hasChangeSuffix(a) && hasChangeSuffix(b)) {
        const prefixA = removeSuffix(a)
        const prefixB = removeSuffix(b)

        if(prefixA !== prefixB) {
            return prefixA < prefixB ? 1 : -1;
        }

        return numericCategory(a) - numericCategory(b)
    }

    return prefixSort(a, b)
}

export const proposingGroupBy: GroupByProp<PriceLine2> = {
    fixedGroups: [
        ...splitGroup("frame"),
        ...splitGroup("door-hm"),
        ...splitGroup("door-wd"),
        ...splitGroup("door-other"),
        // hardware only has additions and removals, no changes
        ...splitGroup("hardware").filter(v => !v.endsWith(ChangeSuffix)),
        ...splitGroup('div-10/corner-guard'), ...splitGroup('div-10/locker'), ...splitGroup('div-10/mailbox'), ...splitGroup('div-10/wr-accessory'), ...splitGroup('div-10/wr-partition'),
        "project"
    ],
    groupFx: row => {
        const base = getRowCategoryBase(row, false);
        const group = categorizeChange(base, row)
        console.log('group', group)
        return group;
    },
    groupSorter: (a, b, aGroup, bGroup) => {
        // can't change keys b/c it's too entrenched, but update the naming here so we can fix the sort order
        a = a.replace(/door-(wd|other|hm)/, "door/$1").replace(/^frame\//, "frame//")
        b = b.replace(/door-(wd|other|hm)/, "door/$1").replace(/^frame\//, "frame//")

        return categorySort(a, b)
    },
    beforeGroup: BeforeGroup,
    afterGroup: AfterGroup,
    hasBeforeGroup: (group: Group<PriceLine|QuotePriceLine>) => {
        if(group.key === "project") return false;
        return true;
    },
    hasAfterGroup: (group: Group<PriceLine|QuotePriceLine>) => {
        if(group.key.endsWith(AdditionSuffix)) return false;
        if(group.key.endsWith(ChangeSuffix)) return false;
        if(group.key.endsWith(RemovalSuffix)) return true;
        return true;
    }
}

function getRowCategoryBase(row: PriceLine2 | PriceLine | QuotePriceLine, splitHardwareByMfg: boolean = true) {
    if(row.productType === "door") {
        if(row.door.series === hollowMetalDoor) return doorHm
        if(row.door.series === woodDoor) return doorWd
        return doorOther;
    }

    if(row.productType === "door-prep") {
        if(row.doorSeries === hollowMetalDoor) return "door-prep/hm"
        if(row.doorSeries === woodDoor) return "door-prep/wd"
        return "door-prep/other";
    }

    if(row.productType === "div-10") {
        return "div-10/" + (row.div10?.category || "wr-accessory");
    }

    if(row.productType === "hardware") {
        if(splitHardwareByMfg) {
            return "hardware/" + row.supplierName;
        }

        return "hardware";
    }

    return row.productType;
}

export const commonGroupBy: GroupByProp<PriceLine|QuotePriceLine> = {
    fixedGroups: [
        "frame", "frame-anchor", "frame-prep", "frame-totals",
        "door-hm", "door-wd", "door-other", "door-prep", "door-totals",
        "hardware-before", "hardware-after",
        'div-10/corner-guard', 'div-10/locker', 'div-10/mailbox', 'div-10/wr-accessory', 'div-10/wr-partition',
        "project"
    ],
    groupFx: row => {
        return getRowCategoryBase(row)
    },
    groupSorter: (a, b, aGroup, bGroup) => {
        if(a.startsWith("hardware/") && b.startsWith("hardware/")) {
            return bGroup.rows.length - aGroup.rows.length;
        }

        return prefixSort(a, b)
    },
    beforeGroup: BeforeGroup,
    afterGroup: AfterGroup,
    hasBeforeGroup: (group: Group<PriceLine|QuotePriceLine>) => {
        if(group.key === "project") return false;
        if(group.key === "hardware-after") return false;
        if(group.key === "frame-totals") return false;
        if(group.key === "door-totals") return false;
        return true;
    },
    hasAfterGroup: (group: Group<PriceLine|QuotePriceLine>) => {
        if(group.key === "hardware-before") return false;
        if(group.key.startsWith("hardware/")) return false
        return true;
    }
}

export function isPriceLine2(input: PriceLine|PriceLine2|QuotePriceLine): input is PriceLine2 {
    return "kind" in input
}

export const doorHm = "door-hm";
export const doorWd = "door-wd";
export const doorOther = "door-other";

function byPrefix(prefixes: string[]) {
    const matchIndex = (search: string) => {
        let matchedI = Infinity;
        let matchedLen = 0;

        // try to find exact match first, then prefix match by longest prefix
        for(let i = 0; i < prefixes.length; i++) {
            if(search === prefixes[i])
                return i;

            if(search.startsWith(prefixes[i])) {
                if(prefixes[i].length >= matchedLen) {
                    matchedLen = prefixes[i].length;
                    matchedI = i;
                } else {
                    // ignore b/c we have a longer prefix match already
                }
            }
        }

        if(matchedI < prefixes.length+1) {
            return matchedI;
        }

        return prefixes.length + 1;
    }

    return (a: string, b: string) => {
        return matchIndex(a) - matchIndex(b)
    }
}

export const commonPricingColumns: (u: User, isQuote: boolean, proposing?: boolean) => Column<PriceLine|QuotePriceLine|PriceLine2>[] = (u: User, isQuote: boolean, proposing: boolean = false) => [
    ViewOnly<PriceLine|PriceLine2|QuotePriceLine>({
        name: qtyColumnName,
        render: (o) => {
            const val = (lookupNestedProp(o, "qty") as any)
            if(!isQuote && val === 0) return "";
            return val?.toString()
        },
        editable: {type: "number"},
        editKey: "qty",
        width: 100,
        alignRight: true,
        // @ts-ignore
        customizeKeepWhite: true,
        filter: numberMatch("qty"),
        sort: sortNumeric("qty"),
    }),
    AdjustCol(CentsCol(costEachColumnName, "unitCostCents", 80), {
        showHintOnFocus: CostFocuser,
        // @ts-ignore
        customizeKeepWhite: true,
        disabled: dt => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return true;
                if(dt.proposingTag === "replace") return true;
                if(dt.proposingTag === "change") return true;
            }

            return false
        },
        render: (dt) => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return ""
                if(dt.proposingTag === "replace") return ""
                if(dt.proposingTag === "change") return ""
            }

            // @ts-ignore
            return formatCents(dt.unitCostCents)
        }
    }),
    {
        name: markupColumnName,
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return ""
                if(dt.proposingTag === "replace") return ""
                if(dt.proposingTag === "change") return ""
            }

            if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
            if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
            return "auto"
        },
        disabled: dt => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return true;
                if(dt.proposingTag === "replace") return true;
                if(dt.proposingTag === "change") return true;
            }

            return false
        },
        editable: {
            type: "custom",
            copy: dt => {
                if(!isNullOrUndefined(dt.markupPercent)) return dt.markupPercent + "%"
                if(!isNullOrUndefined(dt.markupCents)) return "$" + formatCents(dt.markupCents)
                return "auto"
            },
            paste: (rw, value) => {
                Object.assign(rw, parseMarkup(value))
            },
            render: MarkupEditCell,
        },
        alignRight: true,
        width: 80,
    },
    {
        name: "Unit Price",
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => {
            if(proposing && isPriceLine2(dt) && dt.kind === "current") {
                if(dt.proposingTag === "remove") return ""
                if(dt.proposingTag === "replace") return ""
                if(dt.proposingTag === "change") return ""
            }

            return formatCents(dt.extendedPrice / fixZero(dt.qty))
        },
        alignRight: true,
        width: 80,
    },
    isQuote && !proposing ? CentsCol("Price", "extendedPrice", 70) : {
        name: "Price",
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => formatCents(dt.extendedPrice),
        alignRight: true,
        width: 70,
        editable: {
            type: "custom",
            paste: (row, value) => {
                // do nothing
            },
            copy: (row) => {
                return formatCents(row.extendedPrice)
            },
            render: (props) => {
                return <ExtendedPriceEditor value={props.row as any} onDone={props.onDone} onCancel={props.onCancel} anchor={props.anchor} />
            }
        }
    },
    proposing && {
        name: "Price Desc.",
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => {
            if(!isPriceLine2(dt)) return "";
            if(dt.kind !== "current") return "";
            switch(dt.proposingTag) {
                case "remove":
                    if(dt.extendedPrice === dt.previous.extendedPrice) {
                        return <div style={{fontSize: "0.8rem", color: red["700"], fontWeight: "700"}}>Full Refund</div>
                    }

                    return <div style={{fontSize: "0.8rem", color: deepOrange["700"], fontWeight: "700"}}>Partial Credit</div>
                case "new":
                    return <div style={{fontSize: "0.8rem", color: green["700"], fontWeight: "700"}}>Extra</div>
                case "change":
                case "replace":
                    if(dt.extendedPrice < 0) {
                        return <div style={{fontSize: "0.8rem", color: deepOrange["700"], fontWeight: "700"}}>Credit</div>
                    }

                    return <div style={{fontSize: "0.8rem", color: green["700"], fontWeight: "700"}}>Extra</div>
                default:
                    return "-"
            }
        },
        width: 100,
    },
    hasPermission(u, "CanViewProjectPricingProfit") && {
        name: "Proj. Profit",
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => {
            if(isPriceLine2(dt)) {
                if(dt.kind === "previous") return "";
            }

            return formatCents(profit(dt))
        },
        alignRight: true,
        width: 90,
    },
    hasPermission(u, "CanViewProjectPricingProfit") && {
        name: "Act. Profit",
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => {
            if(isNullOrUndefined(dt.revenueDelivered) || isNullOrUndefined(dt.costDelivered)) return "";
            return formatCents(dt.revenueDelivered - dt.costDelivered);
        },
        alignRight: true,
        width: 90,
    },
    {
        name: "Proj. Margin",
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => {
            let profit = dt.extendedPrice - dt.unitCostCents * dt.qty;
            if(profit === 0) return "0%"
            let margin = Math.round(profit / dt.extendedPrice * 100);
            return margin + "%"
        },
        alignRight: true,
        width: 100,
    },
    {
        name: "Suggested Markup",
        // @ts-ignore
        customizeKeepWhite: true,
        render: dt => {
            return "35%"
        },
        alignRight: true,
        width: 150,
    },
    !isQuote && {
        name: "Proposal Name",
        render: renderProposalName,
        // @ts-ignore
        customizeKeepWhite: true,
        sort: (a, b) => {
            const aVal = renderProposalName(a)
            const bVal = renderProposalName(b)
            return sortStringInner(aVal || "", bVal || "")
        },
        filter: (dt, str) => {
            return renderProposalName(dt).toLowerCase().includes(str.toLowerCase())
        },
        width: 200,
    }
]

function fixZero(value: number) {
    if(value === 0) return 1;
    return value;
}

function renderProposalName(dt: PriceLine| QuotePriceLine) {
    const value = dt.projectChangesetActive ? "(current change proposal)" : dt.projectChangesetNote
    if(!value) return ""
    return value;
}