import {PriceLine} from "../../../../api/Pricing";
import {useProjectId} from "../ProjectName";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../../../api/API";
import {FreeCell, TableContext} from "../../../../misc/scroller/Table";
import {useContext, useRef, useState} from "react";
import {Button, Checkbox, CircularProgress, FormControlLabel, Grid, InputAdornment, TextField} from "@mui/material";
import {Popover} from "../../../../misc/Popover";
import {Loader} from "../../../../misc/Loader";
import {css} from "@emotion/css";
import {blue} from "@mui/material/colors";
import {useSyncedRef} from "../../../../misc/SyncedRef";
import {PlainHeader} from "./PlainHeader";
import {IndexCounter} from "./IndexCounter";
import {QuotePriceLine, SectionName} from "../../../../api/QuotePricing";
import {AdditionSuffix, ChangeSuffix, doorHm, doorOther, doorWd, PriceLine2, RemovalSuffix} from "./Frames";
import {useAlternative} from "../../quote/alternative/Alternative";
import {useColumnIndex} from "../../../../misc/scroller/GroupRowUtil";

export function BeforeGroup(props: {
    name: string;
    rows: PriceLine[]|PriceLine2[]|QuotePriceLine[];
}) {

    let changePrefix = "";
    if(props.name.startsWith("door-prep")) {
        changePrefix = "Door Prep ";
    } else if(props.name.startsWith("frame-prep")) {
        changePrefix = "Frame Prep ";
    }

    if(props.name.endsWith(AdditionSuffix)) {
        return <PlainHeader name={changePrefix + "Additions"} />
    } else if(props.name.endsWith(ChangeSuffix)) {
        return <PlainHeader name={changePrefix + "Changes"} />
    } else if(props.name.endsWith(RemovalSuffix)) {
        return <PlainHeader name={changePrefix + "Removals"} />
    }

    if(props.name.startsWith("hardware/")) {
        return <PlainHeader name={props.name} />
    }

    let name = props.name as SectionName;
    if(props.name === "hardware-before") {
        name = "hardware";
    }

    if(name.startsWith("door-prep")) {
        name = "door-prep";
    }

    switch(name) {
        case "hardware":
        case "frame":
        case "frame-prep":
        case "door-prep":
        case "frame-anchor":
        case "door-hm":
        case "door-wd":
        case "door-other":
        // case "div-10":
        case 'div-10/corner-guard':
        case 'div-10/locker':
        case 'div-10/mailbox':
        case 'div-10/wr-accessory':
        case 'div-10/wr-partition':
            return <BeforeGroupInner name={name} rows={props.rows} />
        default:
            return null;
    }
}

export function getSectionName(name: string) {
    switch(name) {
        case "door-totals": return "Page";
        case "frame-totals": return "Page";
        case "frame": return "Frames";
        case "frame-prep": return "Frame Preps";
        case "door-prep/hm":
        case "door-prep/wd":
        case "door-prep/other":
        case "door-prep": return "Door Preps";
        case "frame-anchor": return "Frame Anchors";
        case "door": return "Doors";
        case doorWd: return "Doors - Wood"
        case doorHm: return "Doors - HM"
        case doorOther: return "Doors - Other"
        case "hardware": return "Hardware";
        case "div-10": return "Div 10";
        case 'div-10/corner-guard': return "Corner Guard"
        case 'div-10/locker': return "Locker"
        case 'div-10/mailbox': return "Mailbox"
        case 'div-10/wr-accessory': return "W/R Accessory"
        case 'div-10/wr-partition': return "W/R Partition"
        default:
            if(name.startsWith("hardware/")) return "Hardware";
            if(name.startsWith("door-")) return "Doors";
            console.warn("missing section name for", name);
            return "";
    }
}

export const markupColumnName = "Markup"

function BeforeGroupInner(props: {
    name: string;
    rows: PriceLine[]|PriceLine2[]|QuotePriceLine[];
}) {
    const project = useProjectId();
    const data = useAsync2((args) => api.pricing.getProjectMarkups(args), {project: project}, [project])
    const tblCtx = useContext(TableContext);
    const reload = useSyncedRef(tblCtx.reload);

    const reloadData = useRef(data.reload)
    const update = useAsyncAction(async (args) => {
        await api.pricing.updateProjectMarkups(args);
        reloadData.current();
        reload.current(false);

        setEditEl(null);
        return null;
    }, []);

    const [editEl, setEditEl] = useState<HTMLElement|null>(null);
    const [value, setValue] = useState("");
    const counter = new IndexCounter();
    const [updateExisting, setUpdateExisting] = useState(true);
    const key = props.name
    const alternative = useAlternative();
    const showMarkup = props.name !== "frame-anchor" && !props.name.includes("prep")

    const startIndex = useColumnIndex(markupColumnName)

    return (
        <>
            <FreeCell index={counter.incrBy(startIndex)} colSpan={startIndex}>
                <div className={groupTitle}>
                    {getSectionName(props.name)}
                </div>
            </FreeCell>
            {showMarkup && <FreeCell index={counter.incrBy(3)} colSpan={3}>
                <div style={{
                    marginTop: -4, marginBottom: -4,
                    marginLeft: -6, marginRight: -6,
                    paddingLeft: 6, paddingRight: 6,
                    paddingTop: 4, paddingBottom: 4,
                }}
                     className={clickable}
                     onClick={e => {
                         // @ts-ignore
                         setValue(data.result[key] || "");
                         setEditEl(e.currentTarget);
                     }}
                >
                    <Grid container
                          justifyContent="space-between"
                          style={{width: "100%"}}>
                        <Grid item className={groupTitle}>
                            Default Markup
                        </Grid>
                        {data.loading ? <Grid item><CircularProgress size="inherit" /></Grid> :
                            !!data.error ? <Grid item>{data.error}</Grid> :
                                <Grid item>
                                    {data.result && (data.result as any)[key]}%
                                </Grid>}
                    </Grid>
                </div>
                {!!editEl && <Popover open anchorEl={editEl} onClose={() => setEditEl(null)}>
                    <div style={{padding: 8}}>
                        <form onSubmit={e => {
                            e.preventDefault();

                            update.callback({
                                project: project,
                                alternative: alternative,
                                [key]: tryParse(value),
                                updateExisting: updateExisting,
                            })
                        }}>
                            <div className={groupTitle}>
                                Default Markup
                            </div>
                            <div style={{height: 8}} />
                            <TextField variant="outlined"
                                       placeholder="5"
                                       value={value}
                                       onChange={e => setValue(e.target.value)}
                                       InputProps={{
                                           inputProps: {
                                               style: {textAlign: "right"}
                                           },
                                           endAdornment: <InputAdornment position="end">%</InputAdornment>
                                       }} />
                            <div style={{height: 8}} />
                            <FormControlLabel control={<Checkbox checked={updateExisting}
                                                                 onChange={(e, checked) => setUpdateExisting(checked)} />} label="Update existing markups" />
                            <div style={{height: 8}} />
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Loader {...update} />
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => setEditEl(null)}>Cancel</Button>
                                </Grid>
                                <Grid item>
                                    <Button type="submit">
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Popover>}
            </FreeCell>}
            <FreeCell index={counter.next()} grow />
        </>
    );
}


function tryParse(input: string) {
    const v = parseInt(input)
    if(isNaN(v)) return input as any;
    return v;
}

const clickable = css({
    border: "1px solid transparent",
    cursor: "pointer",

    "&:hover": {
        borderColor: blue["600"],
    }
})

export const groupTitle = css({
    fontSize: "0.9rem",
    fontWeight: 500,
})