import {hasPermission, useUser} from "../../../../misc/Permission";
import {getAll} from "../openings/Openings";
import {api} from "../../../../api/API";
import {
    AdjustCol,
    StringCol,
    Table,
    ViewOnly
} from "../../../../misc/scroller/Table";
import {priceTableName} from "./Pricing";
import {useProjectId} from "../ProjectName";
import React, {useContext} from "react";
import {ManagerLockContext} from "../ProjectActions";
import {commonGroupBy, commonPricingColumns, PriceLine2, proposingGroupBy} from "./Frames";
import {priceDetailEmitter} from "./UnPricedItems";
import {useProposingKit} from "./ProposingKit";
import {ChangePreviewContext} from "../ShopDrawingChangePreview";
import {QuotePriceLine} from "../../../../api/QuotePricing";
import {DoorSectionProvider} from "./DoorSectionProvider";

export function Doors(props: {
    type: "hm" | "wd" | "other"
}) {
    const project = useProjectId();
    const {locked} = useContext(ManagerLockContext)
    const u = useUser();
    const proposal = useProposingKit()
    const changesetPreview = useContext(ChangePreviewContext);

    if(!u) return null;

    const isWood = props.type === "wd"

    return (
        <DoorSectionProvider value={props.type}>
            <Table<PriceLine2>
                key={props.type}
                name={priceTableName(project, "door", props.type)}
                globalPrefsName={priceTableName(0, "door", props.type)}
                locked={locked && !hasPermission(u, "EditShopDrawingWhenLockedToManager")}
                cellCustomize={proposal.cellCustomize}
                fetch={async (ctx) => {
                    const rs = await getAll(ctx, offset =>
                            api.pricing.list({
                                project: project,
                                offset,
                                previewChangeset: changesetPreview.enabled ? changesetPreview.sessionId : undefined
                            }))

                    const prices = proposal.transformRows(rs)

                    priceDetailEmitter.emit(prices);
                    return prices;
                }}
                columns={[
                    {
                        name: "",
                        render: o => proposal.rowAction(o, "door", props.type),
                        width: 150,
                    },
                    proposal.proposing && ViewOnly(AdjustCol(StringCol("Opening", "openingName", 150), {
                        render: (d: PriceLine2) => {
                            return proposal.renderItemName(d, "openingName")
                        }
                    })),
                    ViewOnly({
                        name: "Series",
                        render: (dt: QuotePriceLine) => {
                            if(dt.productType === "door-prep") return `${dt.productName}: ${dt.productCode}`;
                            return dt.door.series;
                        },
                        width: 150,
                    }),
                    ViewOnly(StringCol("Material", "door.material")),
                    isWood ? ViewOnly(StringCol("Finish", "door.finish")) : null,
                    ViewOnly(StringCol("Gauge", "door.gauge")),
                    ViewOnly(StringCol("Elevation", "door.elevation")),
                    ViewOnly(StringCol("Core", "door.core")),
                    ViewOnly(StringCol("Seam", "door.seam")),
                    ViewOnly(StringCol("Handing", "door.handing")),
                    ViewOnly(StringCol("Label", "door.label")),
                    ViewOnly(StringCol("Height", "door.height")),
                    ViewOnly(StringCol("Width", "door.width")),
                    ViewOnly(StringCol("Thickness", "door.thickness")),
                    ...proposal.modifyColumns(commonPricingColumns(u, false, proposal.proposing)),
                ]}
                fetchDeps={[project, proposal.proposing, proposal.expanded, changesetPreview.enabled, changesetPreview.sessionId]}
                columnDeps={[props.type, u, proposal.proposing]}
                groupBy={Object.assign({}, proposal.proposing ? proposingGroupBy : commonGroupBy, {
                    groupFilter: (groupName: string) => {
                        return groupName === "door-" + props.type || groupName.startsWith("door-" + props.type + "/") || groupName === "project"
                            || groupName.startsWith("door-prep/" + props.type)
                    },
                })}
                onChange={(input) => api.pricing.upsert(proposal.processUpdate(input))}
            />
        </DoorSectionProvider>
    )
}
