import {useContext} from "react";
import {ChangesetContext} from "../changeset/ChangesetContext";
import {PriceLine} from "../../../../api/Pricing";
import {Column} from "../../../../misc/scroller/Table";
import {cellInActive} from "./cellconst";
import {useIsQuote} from "../../quote/QuoteContainer";
import {QuotePriceLine} from "../../../../api/QuotePricing";

export function usePriceCellCustomize() {
    const isQuote = useIsQuote();
    const changeset = useContext(ChangesetContext);

    if(isQuote) return undefined;

    return {
        backgroundColor: (row: PriceLine|QuotePriceLine, c: Column<PriceLine|QuotePriceLine>) => {
            if(changeset.proposing) {
                if (row.projectChangesetActive) return "white";
                return cellInActive;
            }

            if(row.projectChangesetApproved) return "white";
            return cellInActive;
        }
    };
}