import {Container} from "../../../misc/Container";
import {SectionTitle} from "../../../misc/SectionTitle";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useContext, useMemo, useState} from "react";
import {getAll} from "../../project/shopdrawing/openings/Openings";
import {api} from "../../../api/API";
import {CentsCol, sortStringInner, StringCol, Table, ViewOnly} from "../../../misc/scroller/Table";
import {Product} from "../../../api/Products";
import {Checkbox2Col} from "../../project/shopdrawing/ShopTableCol";
import {CategoryTree} from "../../project/shopdrawing/extras/CategoryTree";
import {useAsyncAction} from "nate-react-api-helpers";
import {useSyncedRef} from "../../../misc/SyncedRef";
import {beforeGroupClass} from "../../project/shopdrawing/keying/Keying";
import {ManufacturerSelector} from "./ManufacturerSelector";
import {TabSection} from "./Tabs";
import {ProductCatalogLookup} from "../../project/shopdrawing/extras/ProductCatalogLookup";
import {CreateProduct} from "../../project/shopdrawing/extras/CreateProduct";
import {EditContext} from "../../../misc/scroller/EditProvider";
import {PriceRequestBtn} from "./priceRequest/PriceRequestBtn";
import {DistributorSelector} from "./DistributorSelector";
import {RowOptionCol} from "../../logistics/purchasing/RowOptions";

export function Products(props: {
    tab: string;
    onTabChange(value: string): void;
}) {
    const [manufacturer, setManufacturer] = useState<number|null>(null);
    const tableName = "settings.product";
    const [editProduct, setEditProduct] = useState<Product|null>(null);
    const [refreshKey, setRefreshKey] = useState("0")

    return <Container>
        <div style={{
            backgroundColor: "white",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            flex: 1,
        }}>
            <div style={{
                padding: 16, width: "100%",
                display: "flex", justifyContent: "space-between"
            }}>
                <SectionTitle>
                    Product Settings
                </SectionTitle>
                <div>
                    <PriceRequestBtn />
                </div>
            </div>
            <TabSection value={props.tab} onChange={props.onTabChange} right={
                <ManufacturerSelector defaultValueTitle="All Manufacturers" value={manufacturer} onChange={(v) => {
                    setManufacturer(v);
                }} />
            } />

            {editProduct && <CreateProduct
                mode={editProduct.kind}
                edit={editProduct}
                onDone={() => {
                    setEditProduct(null);
                    setRefreshKey(Date.now().toString())
                }}
                onCancel={() => {
                    setEditProduct(null);
                    setRefreshKey(Date.now().toString())
                }} />}

            <Table<Product>
                key={refreshKey}
                name={tableName}
                fetch={ctx => getAll(ctx, offset => api.products.list({
                    manufacturer: manufacturer || undefined,
                    offset: offset,
                    forProductSettings: true,
                }))}
                columns={[
                    RowOptionCol({
                        options: (dt) => [
                            {
                                name: "Edit in Popup",
                                onClick: (ctrl) => {
                                    setEditProduct(dt);
                                    ctrl.onClose()
                                }
                            }
                        ],
                    }),
                    Checkbox2Col("Hide", "hideFromSearch"),
                    ViewOnly({
                        name: "Type",
                        width: 100,
                        render: row => getType(row) as any,
                        filter: (row: Product, search: string) => {
                            return getType(row).toLowerCase().indexOf(search.toLowerCase()) === 0;
                        },
                        sort: (a, b) => sortStringInner(getType(a), getType(b)),
                    }),
                    {
                        name: "Name",
                        width: 200,
                        editable: {
                            type: "custom",
                            render: CategorySwitcher,
                        },
                        filter: (row: Product, search: string) => {
                            return row.categoryName.toLowerCase().indexOf(search.toLowerCase()) === 0;
                        },
                        render: d => d.categoryName,
                        sort: (a, b) => sortStringInner(a.categoryName, b.categoryName),
                    },
                    StringCol("Code", "productCode"),
                    StringCol("Finish", "finish"),
                    StringCol("Finish Desc.", "finishName"),
                    {
                        name: "Distributor",
                        width: 200,
                        editable: {
                            type: "custom",
                            render: DistributorSelector,
                        },
                        render: d => d.defaultDistributorName || "",
                        sort: (a, b) => sortStringInner(a.defaultDistributorName || "", b.defaultDistributorName || ""),
                    },
                    Checkbox2Col("Stocking", "isStocking"),
                    Checkbox2Col("Keyed", "keyed"),
                    CutSheetCol(),
                    CentsCol("Df. Cost", "defaultCostCents"),
                    ViewOnly(StringCol("L. Formula", "dimLengthFormula")),
                    ViewOnly(StringCol("W. Formula", "dimWidthFormula")),
                    ViewOnly(StringCol("H. Formula", "dimHeightFormula")),
                ]}
                fetchDeps={[manufacturer]}
                groupBy={{
                    beforeGroup: (props) => {
                       return <div className={beforeGroupClass}>
                           {props.name}
                       </div>
                    },
                    groupFx: input => input.manufacturerName,
                    groupSorter: (aVal, bVal) => {
                        if(aVal === bVal) return 0;
                        if(aVal < bVal) return -1;
                        return 1;
                    }
                }}
                onChange={product => api.products.upsert(product)}
                insert={{
                    buttonText: "New Product",
                    alignX: "left",
                    modal: input => <CreateModal onDone={input} />
                }}
            />
        </div>
    </Container>
}

function CreateModal(props: { onDone(): void; }) {
    const ctx = useContext(EditContext);

    return (
        <CreateProduct onDone={() => {

            ctx.reload();
            props.onDone();

        }} onCancel={() => {

            ctx.reload();
            props.onDone();

        }} mode={null} />
    )
}

function CutSheetCol() {
    return {
        name: "Catalog",
        width: 300,
        editable: {
            type: "custom",
            paste: (row: Product, value: string) => {
                if(value) {
                   row.catalog = parseInt(value);
                } else {
                    row.catalog = undefined;
                }
            },
            copy: (row: Product) =>{
                return row.catalog?.toString() || ""
            },
            render: CutSheetChanger,
        },
        render: (input: Product) => input.catalog ?
            <a style={{marginRight: 10}} href={api.productCatalogs.fileLink(input.catalog)}>{input.catalogName}</a> :
            "No Catalog",
    }
}

function CutSheetChanger(props: {
    anchor: any;
    row: Product;
    width: number;
    initialValue?: string;
    onCancel(): void;
    onDone(value: Product): Promise<any>;
}) {
    const catalog =  useMemo(() => props.row.catalog ?
        {
            id: props.row.catalog,
            name: props.row.catalogName || "",
            pages: props.row.catalogPages || "",
        } : null, [props.row])
    const onDoneRef = useSyncedRef(props.onDone);

    return (
        <ProductCatalogLookup name="Catalog" onDone={async (v) => {
            onDoneRef.current(Object.assign({}, props.row, {
                catalog: v?.id || null,
                catalogPages: v?.pages || "",
            }));
        }} value={catalog} manufacturer={props.row.manufacturer} />
    )
}

function getType(d: Product) {
    if(d.frameSpec) return "Frame";
    if(d.doorSpec) return "Door";
    if(d.div10Spec) return "Div 10";
    if(d.kind === "div-10") return "W/R Acc"
    return "Hardware";
}

function CategorySwitcher<T extends Product>(props: {
    anchor: any;
    row: T;
    width: number;
    initialValue: string;
    onCancel(): void;
    onDone(value: T): Promise<any>
}) {
    const row = props.row;
    const [category, setCategory] = useState(row.category);
    const onDoneRef = useSyncedRef(props.onDone);
    const categoryRef = useSyncedRef(category);

    const action = useAsyncAction(() => onDoneRef.current(Object.assign({}, props.row, {
        category: categoryRef.current,
    })), []);


    if(row.frameSpec) return null;
    if(row.doorSpec) return null;

    return (
        <Dialog open={true}>
            <DialogTitle>
                Edit Category
            </DialogTitle>
            <DialogContent>
                <CategoryTree value={category}
                              mode={row.kind === "div-10" ? "div-10" : "hardware"}
                              onChange={v => setCategory(v || row.category)} />
            </DialogContent>
            <DialogActions>
                {action.LoadingElement}
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button onClick={() => action.callback(null)}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}